import { MessageSetting } from "graphql/types";
import { ApolloQueryResult, makeVar, useApolloClient } from "@apollo/client";
import {
  SelfFragment,
  useSelfQuery,
  SelfQuery,
  useUpsertMessageSettingsMutation,
  NotificationConfigFragment,
} from "./graphql.generated";
import { useCallback, useMemo } from "react";

import { config } from "lib/config";

const fetched = makeVar(false);

export type UseSelfResult = {
  loading: boolean;
  isPuzzlenaut: boolean;
  self?: SelfFragment | null;
  notificationConfig?: NotificationConfigFragment | null;
  intercomHash?: string | null;
  refetch: () => Promise<ApolloQueryResult<SelfQuery>>;
  messageSettings: Partial<Record<string, boolean>>;
  upsertMessageSettings: (key: string, value: boolean) => Promise<void>;
};

const PUZZLE_DOMAINS = ["puzzle.io", "valenciadata.com"];

const userIsPuzzle = (user: SelfFragment) => {
  const domain = user.email ? user.email.split("@")[1] : "";

  if (PUZZLE_DOMAINS.includes(domain)) {
    return true;
  }
  return false;
};

const addOrModifyMessageSettings = (
  messageSettings: MessageSetting[],
  key: string,
  value: boolean
): MessageSetting[] => {
  if (messageSettings.find((s) => s.key === key)) {
    return messageSettings.map((s) => {
      if (s.key !== key) {
        return s;
      }
      return { ...s, value };
    });
  } else {
    return [...messageSettings, { __typename: "MessageSetting", key, value }];
  }
};

// TODO this should be turned into Context for safety
export function useSelf(): UseSelfResult {
  const { data, loading, refetch } = useSelfQuery({
    fetchPolicy: fetched() ? "cache-first" : "cache-and-network",
    variables: {
      includeNotifications: config.NOTIFICATIONS_ENABLED,
    },
    onCompleted: () => {
      fetched(true);
    },
  });

  const isPuzzlenaut = Boolean(data?.self && userIsPuzzle(data.self));
  const { cache } = useApolloClient();
  const [_upsertMessageSettings] = useUpsertMessageSettingsMutation();

  const _messageSettings = useMemo(
    () => data?.self?.messageSettings || [],
    [data?.self?.messageSettings]
  );

  const messageSettings = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(_messageSettings).map(([, { key, value }]) => [key, value])
      ),
    [_messageSettings]
  );

  const upsertMessageSettings = useCallback(
    async (key: string, value: boolean) => {
      await _upsertMessageSettings({
        variables: {
          input: {
            key,
            value,
          },
        },
        optimisticResponse: {
          upsertMessageSettings: {
            __typename: "User",
            id: cache.identify(data!.self!),
            messageSettings: addOrModifyMessageSettings(_messageSettings, key, value),
          },
        },
      });
    },
    [_messageSettings, _upsertMessageSettings, cache, data]
  );

  return useMemo(() => {
    return {
      isPuzzlenaut,
      loading,
      intercomHash: data?.intercomHash,
      notificationConfig: data?.getNotificationToken,
      self: data?.self,
      messageSettings,
      upsertMessageSettings,
      refetch,
    };
  }, [isPuzzlenaut, loading, data, messageSettings, upsertMessageSettings, refetch]);
}

export default useSelf;
