import React, { LabelHTMLAttributes, ReactNode, useMemo, useId } from "react";

import * as Stitches from "@stitches/react";

import { styled } from "@puzzle/theme";
import { Feedback } from "./types";
import { Help } from "../Help";

export type FieldContextType = {
  feedback?: Feedback;
  message?: string;
};

const FieldContext = React.createContext<FieldContextType | null>(null);

export const useFieldContext = () => {
  const context = React.useContext(FieldContext);

  return context || {};
};

const Label = styled("label", {
  display: "flex",
  alignItems: "center",
  gap: "$0h",
  fontWeight: "$bold",
  fontSize: "$bodyS",
  lineHeight: "$bodyXS",
});

const Message = styled("div", {
  marginTop: "$0h",
  textVariant: "$bodyXS",

  variants: {
    feedback: {
      negative: {
        color: "$red600",
      },
      positive: {
        color: "$green600",
      },
      neutral: {
        marginTop: "0",
        color: "$gray500",
      },
    },
  },
});

const Control = styled("div");

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",

  defaultVariants: {
    readOnly: false,
  },

  variants: {
    readOnly: {
      true: {
        gap: "$1h",

        [`${Label}`]: {
          color: "$gray500",
        },

        [`${Control}`]: {
          color: "$neutral100",
          fontSize: "$bodyS",
          lineHeight: "$bodyXS",
        },
      },
      false: {
        gap: "$1",

        [`${Label}`]: {
          color: "$gray200",
        },
      },
    },
  },
});

export const Field = ({
  label,
  id,
  children,
  feedback,
  message,
  error,
  hint,
  readOnly,
  css,
  ...labelProps
}: {
  label?: ReactNode;
  id?: string;
  feedback?: Feedback;
  message?: string;
  error?: string;
  children: React.ReactElement;
  hint?: string;
  readOnly?: boolean;
  css?: Stitches.CSS;
} & LabelHTMLAttributes<HTMLLabelElement>) => {
  const generatedId = useId();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const controlId = useMemo(() => id || generatedId, [id]);
  const context = useMemo(() => {
    return {
      feedback: error ? "negative" : feedback,
      message: error || message,
    };
  }, [error, feedback, message]);

  return (
    <FieldContext.Provider value={context}>
      <Container readOnly={readOnly} css={css}>
        {label && (
          <Label htmlFor={controlId} {...labelProps}>
            {label}
            {hint && <Help content={hint} />}
          </Label>
        )}

        <Control>{React.cloneElement(children, { id: controlId })}</Control>

        {context.message && <Message feedback={context.feedback}>{context.message}</Message>}
      </Container>
    </FieldContext.Provider>
  );
};
