import React, { useEffect, useMemo, useState } from "react";
import {
  type DateSegment,
  DateFieldState,
  DateFieldStateOptions,
  useDateFieldState,
} from "@react-stately/datepicker";
import { useLocale } from "@react-aria/i18n";
import { useDateField, useDateSegment } from "@react-aria/datepicker";
import { CalendarDate, createCalendar, DateValue, toCalendarDate } from "@internationalized/date";
import { Granularity } from "@react-types/datepicker";

import { Calendar, CalendarAdd, Clear, LockOutline } from "@puzzle/icons";
import { CalendarView, DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from "@puzzle/utils";

import { styled, CSS, shadows, colors } from "@puzzle/theme";
import { DatePickerCalendar } from "../dates/DatePickerCalendar";
import { Input, InputVariants } from "./Input";
import { Popover } from "../Popover";
import { useInDialogContext } from "../InDialogContext";
import { IconButton } from "../Button";

const DateSegmentRoot = styled("div", {
  display: "inline-block",
  lineHeight: "14px",
  "&:focus": {
    outline: "none",
    borderRadius: "$xs",

    backgroundColor: "$rhino200",
    color: "$gray200",
    boxShadow: shadows.rhino200BlurSmall,
  },

  variants: {
    isPlaceholder: {
      true: {
        color: "$gray500",
      },
      false: {},
    },
  },
});

const IconTrigger = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  width: "24px",
  height: "24px",

  borderRadius: "8px",
  color: colors.white100,
  backgroundColor: colors.white50,

  cursor: "pointer",
});

const DateSegment = ({ segment, state }: { segment: DateSegment; state: DateFieldState }) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <DateSegmentRoot {...segmentProps} ref={ref} isPlaceholder={segment.isPlaceholder}>
      {segment.text}
    </DateSegmentRoot>
  );
};

const KeyboardDateInputRoot = styled("div", {
  display: "flex",
  gap: "3px",
  paddingLeft: "4px !important",
  transform: "translateX(-4px)",
});

// I need to revisit Input...
// The popover needs to be anchored to the root, not the actual input.
// Turns out this is a common issue; should probably do ref/inputRef instead of ref/rootRef
type KeyboardDateInputProps = Omit<
  React.ComponentProps<typeof Input>,
  "value" | "defaultValue" | "onChange"
> &
  Omit<
    DateFieldStateOptions,
    | "createCalendar"
    | "locale"
    | "onChange"
    | "value"
    // defaultValue leads to odd situations when we want this field to be nullable
    // Prefer defining a default via value={} instead.
    | "defaultValue"
  > & {
    // undefined will not reset the value... this type seems like a library bug?
    value?: DateValue | null;
    // This was also not quite right in the library. Clearing a segment returns null...
    onChange?: (value: DateValue | null) => void;
    hideLeftBorder?: boolean;
  } & Omit<React.HTMLAttributes<HTMLDivElement>, "onChange" | "defaultValue" | "prefix"> & {
    inputRef?:
      | ((instance: HTMLInputElement | null) => void)
      | React.MutableRefObject<HTMLInputElement | null>
      | null;
  };
export const KeyboardDateInput = React.forwardRef<HTMLDivElement, KeyboardDateInputProps>(
  (
    {
      onClick,
      onBlur,
      onChange,
      value,
      readOnly,
      granularity,
      onKeyDown,
      hideLeftBorder,
      ...props
    },
    ref
  ) => {
    const { locale } = useLocale();
    const state = useDateFieldState({
      ...props,
      onChange,
      locale,
      createCalendar,
      isReadOnly: readOnly,
      isDisabled: props.disabled,
      granularity,
      value: value === null ? undefined : value,
    });

    const dateInputRef = React.useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
    //@ts-ignore TODO: Fix this https://linear.app/puzzlefin/issue/FE-54/fix-type-issues-in-packagesuisrclibformdateinputtsx
    const { fieldProps } = useDateField(props, state, dateInputRef);
    return (
      <Input
        input={
          <KeyboardDateInputRoot
            className="input"
            {...fieldProps}
            // Not sure why, but useDateField seems to override onClick
            // manually making it so onClick comes from props
            onClick={onClick}
            onKeyDown={(e) => {
              onKeyDown?.(e);
              if (!e.defaultPrevented) {
                fieldProps.onKeyDown?.(e);
              }
            }}
          >
            {state.segments.map((segment, i) => (
              <DateSegment key={i} segment={segment} state={state} />
            ))}
          </KeyboardDateInputRoot>
        }
        {...props}
        style={{
          ...props.style,
          pointerEvents: readOnly ? "none" : "initial",
        }}
        rootRef={ref}
        hideLeftBorder={hideLeftBorder}
        ref={dateInputRef}
        onBlur={(e) => {
          // Ignore onBlur when tabbing between date segments
          if (e.target.role === "spinbutton" && e.target.parentElement?.contains(e.relatedTarget)) {
            e.stopPropagation();
            return;
          }

          onBlur?.(e);
        }}
      />
    );
  }
);

const StyledPopover = styled(Popover, {
  display: "flex",
  flexDirection: "column",
  gap: "$1",
  padding: "$2",
  zIndex: 9999,

  variants: {
    dark: {
      true: {
        background: "#242430",
      },
      false: {},
    },
    unit: {
      [CalendarView.Day]: {
        width: 286,
      },
      [CalendarView.Quarter]: {},
      [CalendarView.Month]: {
        width: 280,
      },
      [CalendarView.Year]: {},
    },
  },
});

// NOTE: This expects multiple buttons or a single block element.
// We may need additional variants if more use cases are added.
const Footer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "$1",
  textVariant: "$bodyXS",
  lineHeight: "$bodyS",
  color: "$gray400",

  a: {
    color: "$gray200",
    textDecoration: "underline",
  },

  "& > *": {
    flex: 1,
  },
});

type DatePickerProps = Omit<
  React.ComponentPropsWithoutRef<typeof DatePickerCalendar>,
  "value" | "minDate" | "maxDate" | "onChange"
>;

// TODO Need to start accepting timeZone across Date components
// UPDATE: Started doing this with @internationalized/date
// value={parseDate(string).toDate(timeZone)}
// Still a lot to normalize and clean up
export const DateInput = React.forwardRef<
  HTMLInputElement,
  React.ComponentProps<typeof KeyboardDateInput> &
    Omit<React.HTMLAttributes<HTMLInputElement>, "value" | "defaultValue" | "onChange"> &
    DatePickerProps &
    InputVariants & {
      minDate?: DateValue;
      maxDate?: DateValue;
      // Controllable popover state
      // TODO consider replacing with useDateFieldState's version
      popoverOpen?: boolean;
      onPopoverOpenChange?: (open: boolean) => void;
      closeOnSelection?: boolean;

      autoFocus?: boolean;
      disabled?: boolean;
      readOnly?: boolean;
      iconTrigger?: boolean;

      // This may not be needed unless you need quarter granularity. useDateField supports day/month/year.
      inputReadOnly?: boolean;
      footer?: React.ReactNode;

      css?: CSS;
      dark?: boolean;
      hideIcon?: boolean;
      iconSide?: "left" | "right";
      hideLeftBorder?: boolean;
      iconColor?: string;

      onClear?: () => void;
    }
>(
  (
    {
      popoverOpen,
      onPopoverOpenChange,

      autoFocus,
      disabled,
      closeOnSelection = true,
      footer,
      hideIcon = false,
      iconSide = "left",
      iconColor,
      onChange,
      onClear,
      placeholder = "mm/dd/yy",
      value: _value,
      view = "day",

      minDate = DEFAULT_MIN_DATE,
      maxDate = DEFAULT_MAX_DATE,
      readOnly,
      iconTrigger = false,
      inputReadOnly,

      dark,
      hideLeftBorder,
      css,
      ...props
    },
    ref
  ) => {
    const [_popoverOpen, _setPopoverOpen] = useState(autoFocus);

    const open = popoverOpen ?? _popoverOpen;
    const setOpen = onPopoverOpenChange ?? _setPopoverOpen;

    const value = _value ?? undefined;
    const inDialog = useInDialogContext();

    const [displayedDate, setDisplayedDate] = useState<DateValue | undefined>(value);

    useEffect(() => {
      setDisplayedDate(value);
    }, [value]);

    const granularity = useMemo(() => {
      if (["day", "month", "year"].includes(view)) {
        // NOTE: The types are missing month/year but they do work.
        return view as Granularity;
      }

      return undefined;
    }, [view]);

    const suffix = useMemo(() => {
      if (value && onClear) {
        const clear = (e: Pick<React.PointerEvent, "preventDefault" | "stopPropagation">) => {
          e.preventDefault();
          e.stopPropagation();
          onClear();
        };

        return (
          <IconButton onClick={clear} onKeyDown={clear} size="small">
            <Clear />
          </IconButton>
        );
      }

      return null;
    }, [onClear, value]);
    return (
      <StyledPopover
        data-testid="@puzzle/ui/date-input/styled-popover"
        unit={view as CalendarView}
        open={!disabled && open}
        onOpenChange={setOpen}
        onOpenAutoFocus={(e: any) => e.preventDefault()}
        align="start"
        side="bottom"
        sideOffset={6}
        dark={dark ?? inDialog}
        trigger={
          iconTrigger && !value ? (
            <IconTrigger ref={ref}>
              {disabled ? <LockOutline size={12} /> : <CalendarAdd size={12} />}
            </IconTrigger>
          ) : (
            <KeyboardDateInput
              focused={open}
              data-testid="calendar-date-input-trigger"
              value={displayedDate ?? null}
              prefix={
                !hideIcon &&
                iconSide === "left" && (
                  <Calendar style={{ cursor: "pointer" }} color={iconColor ?? "currentColor"} />
                )
              }
              suffix={
                suffix ||
                (!hideIcon && iconSide === "right" && (
                  <Calendar style={{ cursor: "pointer" }} color={iconColor ?? "currentColor"} />
                ))
              }
              inputRef={ref}
              autoFocus={autoFocus}
              placeholder={placeholder}
              readOnly={readOnly ?? inputReadOnly}
              type="text"
              granularity={granularity}
              rootProps={{
                onClick: () => {
                  if (!readOnly) {
                    setOpen(true);
                  }
                },

                /**
                 * Only call onChange when the user blurs away from the date segments
                 * If it's invalid, reset to the last value
                 * This is a cheap way to not require consumers to provide validation (aside from min/maxDate)
                 */
                onBlur: () => {
                  let newValue = displayedDate;

                  if (newValue) {
                    // If user manually entered a non 4 digit year, assume they meant this millennium
                    if (newValue.year.toString().length < 4) {
                      newValue = new CalendarDate(
                        // Year can have up to 3 digits, just take the last two assuming they meant this century
                        (newValue.year % 100) + DEFAULT_MIN_DATE.year,
                        newValue.month,
                        newValue.day
                      );
                    }

                    // Clamp to the min/max date
                    // NOTE: This behavior would be removed if we validate by function
                    if (minDate && newValue.compare(minDate) < 0) {
                      newValue = minDate;
                    }
                    if (maxDate && newValue.compare(maxDate) > 0) {
                      newValue = maxDate;
                    }
                  }

                  setDisplayedDate(newValue);
                  onChange?.(newValue ?? null);
                },
              }}
              onChange={(value) => {
                // Displayed values are in-progress until you blur (value remains unchanged)
                setDisplayedDate(value ?? undefined);
              }}
              disabled={disabled}
              {...props}
              // When the input is readOnly, disable interacting with it so the click opens the popover.
              // TODO This is only to support month/year-based inputs.
              // We'll need a keyboard-friendly way to input those too (even dropdowns)
              css={css}
              hideLeftBorder={hideLeftBorder}
            />
          )
        }
      >
        <DatePickerCalendar
          data-testid="@puzzle/ui/date-input/date-picker-calendar"
          view={view}
          value={value && toCalendarDate(value)}
          minDate={minDate && toCalendarDate(minDate)}
          maxDate={maxDate && toCalendarDate(maxDate)}
          onChange={(value) => {
            onChange?.(value);
            if (closeOnSelection) {
              setOpen(false);
            }
          }}
        />

        {footer && <Footer>{footer}</Footer>}
      </StyledPopover>
    );
  }
);
