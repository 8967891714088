import React from "react";
import * as Stitches from "@stitches/react";
import { ChevronUp, ChevronDown } from "@puzzle/icons";
import { CalendarDate, getLocalTimeZone } from "@internationalized/date";
import { format } from "date-fns/format";
import { styled, css } from "@puzzle/theme";
import { IconButton } from "../Button";
import { DayOfWeek, Modifiers } from "./types";
import { CalendarView } from "./types";
import { useCalendar } from "./useCalendar";
import { Cell, HeaderCell } from "./Cell";
import { useInDialogContext } from "../InDialogContext";

const contentStyles = css({
  defaultVariants: {
    dark: false,
  },

  variants: {
    dark: {
      true: {
        background: "#242430",
      },
      false: {},
    },
  },
});

const Container = styled("div", contentStyles, {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  background: "$rhino700",
});

const SingleCalendar = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const Header = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "$1",
  fontSize: "14px",
  lineHeight: "22px",
  color: "$white",
  fontWeight: "$bold",
});

const Body = styled("div", {});

const Paginator = styled("div", { display: "flex", marginTop: "$1" });

const WeekHeader = styled("div", {
  display: "grid",
  gridAutoFlow: "column",
  alignItems: "center",
  fontWeight: "$bold",
  fontSize: "13px",
  lineHeight: "14px",
  letterSpacing: "0.5px",
  color: "$mauve500",
});

const CellRow = styled("div", {
  display: "grid",
  gridAutoFlow: "column",
  gridAutoColumns: "minmax(0, 1fr)",
  alignItems: "center",
});

const DateCellFormat = {
  [CalendarView.Day]: "d",
  [CalendarView.Month]: "MMM",
  [CalendarView.Quarter]: "'Q'Q",
  [CalendarView.Year]: "yyyy",
};

export const Calendar = ({
  initialDate,
  modifiers,
  onCellHover,
  onCellClick,
  minDate,
  maxDate,
  firstDayOfWeek = 0,
  showOutsideDays = false,
  view,
  dark,
  isPermanent = false,
  ...props
}: {
  initialDate?: CalendarDate;
  // TODO replace with isDateUnavailable function?
  // WARNING: These are inclusive
  minDate?: CalendarDate;
  maxDate?: CalendarDate;
  modifiers: Modifiers;
  onCellHover?: (date?: CalendarDate | null) => void;
  onCellClick?: (date: CalendarDate) => void;
  view: `${CalendarView}`;
  firstDayOfWeek?: DayOfWeek;
  showOutsideDays?: boolean;
  dark?: boolean;
  css?: Stitches.CSS;
  isPermanent?: boolean;
}) => {
  const tz = getLocalTimeZone();
  const { calendars, headerCells, getDateModifiers, getDateProps, getBackProps, getForwardProps } =
    useCalendar({
      initialDate,
      modifiers,
      minDate,
      maxDate,
      firstDayOfWeek,
      view,
      isPermanent,
    });

  const inDialog = useInDialogContext();

  return (
    <Container dark={dark ?? inDialog} {...props}>
      {calendars.map((calendar, i) => (
        <SingleCalendar key={i}>
          <Header css={{ userSelect: inDialog ? "none" : undefined }}>
            {view === "day" && format(calendar.firstDay.toDate(tz), "MMMM yyyy")}
            {(view === "month" || view === "quarter") && calendar.firstDay.year}
            {view === "year" && `${calendar.firstDay.year} - ${calendar.lastDay.year}`}
            <Paginator>
              <IconButton as={inDialog ? "span" : "button"} {...getBackProps()}>
                <ChevronUp color="currentColor" />
              </IconButton>
              <IconButton as={inDialog ? "span" : "button"} {...getForwardProps()}>
                <ChevronDown color="currentColor" />
              </IconButton>
            </Paginator>
          </Header>

          <Body>
            {headerCells.length > 1 && (
              <WeekHeader role="row">
                {headerCells.map((weekday) => (
                  <HeaderCell key={weekday} role="columnheader">
                    {weekday}
                  </HeaderCell>
                ))}
              </WeekHeader>
            )}

            {calendar.rows.map((row, rowIndex) => {
              return (
                <CellRow key={rowIndex}>
                  {row.map((dateCell, dateIndex) => {
                    const modifiers = getDateModifiers(dateCell.date);
                    const disabled = modifiers.disabled;

                    if (modifiers.outside && !showOutsideDays) {
                      return <Cell disabled key={dateIndex} />;
                    }

                    return (
                      <Cell
                        dark={dark ?? inDialog}
                        as="span"
                        key={dateIndex}
                        unit={view as CalendarView}
                        {...getDateProps({
                          onClick: () => !disabled && onCellClick?.(dateCell.date),
                          onMouseEnter: () => !disabled && onCellHover?.(dateCell.date),
                          onMouseLeave: () => onCellHover?.(null),
                          modifiers,
                          dateCell,
                        })}
                        {...modifiers}
                      >
                        {format(dateCell.date.toDate(tz), DateCellFormat[view])}
                      </Cell>
                    );
                  })}
                </CellRow>
              );
            })}
          </Body>
        </SingleCalendar>
      ))}
    </Container>
  );
};

Calendar.toString = Container.toString;
