import { colors } from "./colors";
import rgba from "polished/lib/color/rgba";

export const gradients = {
  // Used 3 times
  labelLight: `linear-gradient(180deg, #343547 0%, ${colors.mauve700} 100%)`,
  // Used 2 times
  labelDark: `linear-gradient(180deg, #343547 0%, #22222E 100%)`,
  // Used 1 time
  blackMask: `linear-gradient(to bottom, black 0%, black 35%, transparent 100%)`,
  // Used 2 times
  purpleToLightPurple: `linear-gradient(0deg, ${rgba(colors.purple900, 0.25)} 0%, ${rgba(colors.purple500, 0.25)} 100%)`,
  // Used 1 time
  blueToPurple: `linear-gradient(to right, $blue500, $purple500)`,
  // Used 1 time
  purpleToGreen: `linear-gradient(116.54deg, ${rgba(colors.purple600, 0.1)} 10.36%, ${rgba(colors.greenalpha, 0.1)} 92.85%)`,
  // Used 1 time
  purpleToGreenAlt: `linear-gradient(90deg, ${rgba(colors.purple600, 0.24)} 0%, ${rgba(colors.green600, 0.24)} 100%)`,
  // Used 1 time
  purpleToGreenToBlue: `linear-gradient(117deg, ${rgba(colors.purple600, 0.1)} 10.36%, ${rgba(colors.greenalpha, 0.1)} 92.85%), ${colors.mauve800}`,
  // Used 3 times
  purpleToGreenToMauve: `linear-gradient(117deg, ${rgba(colors.purple600, 0.2)} 10.36%, ${rgba(colors.greenalpha, 0.2)} 92.85%), ${colors.mauve900}`,
  // Used 2 times
  blackToBlackToMauve: `linear-gradient(0deg, ${colors.black200} 0%, ${colors.black200} 100%), ${colors.mauve900}`,
  // Used 1 time
  blackToBlackToBlue: `linear-gradient(0deg, ${colors.black200} 0%, ${colors.black200} 100%), #211F33`,
  // Used 1 time
  blackToBlackToBlueAlt: `linear-gradient(0deg, ${colors.black50} 0%, ${colors.black50} 100%), #1A192A`,
  // Used 1 time
  blackToBlackToPurple: `linear-gradient(180deg, ${rgba(colors.black, 0)} 33.85%, ${colors.black200} 100%), ${rgba(colors.purple700, 0.4)}`,
  // Used 1 time
  mauveToBlue: `linear-gradient(0deg, ${colors.mauve900} 0%, ${rgba(colors.neutral900, 0.8)} 100%)`,
  // Used 1 time
  transparentToBottomBlack: `linear-gradient(to bottom, transparent, ${colors.black200})`,
  // Used 1 time
  transparentToTopBlack: `linear-gradient(to top, transparent, ${colors.black200})`,

  // Used 1 time
  greyToBlue: `linear-gradient(0deg, ${colors.white50} 0%, ${colors.white50} 100%), #1A192A`,
  // Used 2 times
  greyToBlueishToGrey: `linear-gradient(-75deg, ${rgba(colors.gray300, 0)} 0%, ${rgba(colors.gray300, 0.3)} 51.28%, ${rgba(colors.gray300, 0)} 100%)`,
  // Used 1 time
  transparentToMauveBottom: `linear-gradient(to bottom, ${colors.mauve800} 0%, ${colors.mauve800} 100%)`,
  // Used 1 time
  purpleToTeal: `linear-gradient(135deg, ${colors.purple600} 0%, ${colors.white} 50%, ${colors.green300} 100%)`,
  // Used 1 time
  radialPurpleToBlack: `radial-gradient(59.63% 59.63% at 50% 0%, ${rgba(colors.purple900, 0.72)} 0%, ${rgba(colors.mauve900, 0)} 100%), #000211`,
  // Used 1 time
  radialGreyToGrey: `radial-gradient(circle farthest-side, #DFE1E4, #EDEFF5)`,
  // Used 1 time
  radialPurpleToBlue: `radial-gradient(circle farthest-side, #6A6379, ${colors.mauve800})`,
};

// Shadow Value Tokens
// Naming Schema
// Format: [color][opacity][To<Color[opacity]>][Direction][Characteristic][Size][Type]
//
// Options:
// - color: black, white, purple, mauve, rhino, green, gray or any from our color pallette
// - opacity: A04, A08, A10, A20, A24, A32
// - direction: Right, Bottom, Left, Top, Horizontal, Vertical
// - characteristic: Blur, Spread
// - size: Nothing, Small, Medium, Large, Huge
// - type: Composed, Inset
//
// Examples:
// - black04ToBlack08BottomBlurMediumComposed
// - mauve800A024BottomBlurSmall
// - white10SpreadInset
// - purple600BlurMedium
// - green600BlurNothing
export const shadows = {
  // Used 1 time
  black04ToBlack08BottomBlurMediumComposed: `0px 4px 4px ${colors.black50}, 0px 8px 16px ${colors.black100}`,
  // Used 3 times
  black04ToBlack08BottomBlurSmallMediumComposed: `0px 4px 4px ${colors.black50}, 0px 8px 16px ${colors.black100}`,
  // Used 1 time
  black08ToBlack04BottomBlurMediumSmallComposed: `0px 8px 16px ${colors.black100}, 0px 4px 4px ${colors.black50}`,
  // Used 1 time
  black08BottomBlurMedium: `0 8px 16px 0 ${colors.black100}`,
  // Used 1 time
  black10BottomBlurMedium: `0px 4px 8px ${colors.black100}`,
  // Used 1 time
  black10BottomBlurSmall: `0 2px 4px ${colors.black100}`,
  // Used 1 time
  black10ToBlack10BottomBlurMediumComposed: `0px 4px 8px ${colors.black100}, 0px 8px 16px ${colors.black100}`,
  // Used 3 times
  black10ToBlack20BottomBlurMediumComposed: `0px 4px 8px ${colors.black100}, 0px 8px 12px ${colors.black200}`,
  // Used 2 times
  black10ToBlack20BottomBlurMediumHugeComposed: `0px 4px 12px 0 ${colors.black100}, 0px 16px 40px 0 ${colors.black200}`,
  // Used 1 time
  black10ToBlack20BottomBlurMediumLargeComposed: `0px 4px 8px ${colors.black100}, 0px 8px 24px ${colors.black200}`,
  // Used 3 times
  black20ToBlack10BottomBlurLargeComposed: `0px 8px 24px ${colors.black200}, 0px 4px 8px ${colors.black100}`,
  // Used 1 time
  black24BottomBlur: `0px 1px 2px ${colors.black300}`,
  // Used 1 time
  black24Toblack24BottomBlurSmallComposed: `0px 1px 4px ${colors.black300}, 0px 1px 2px ${colors.black300}`,
  // Used 2 times
  black25BlurLarge: `0px 0px 50px 0px ${colors.black25}`,
  // Used 1 time
  black32BlurLarge: `0px 0px 24px 0px ${colors.black400}`,
  // Used 2 times
  black32TopBlurLarge: `0px -24px 24px -16px ${colors.black400}`,
  // Used 1 time
  purpleOneOffToBlack10ToBlack20BlurSmallMediumHuge: `0px 0px 0px 1px ${colors.neutral700}, 0px 4px 12px ${colors.black100}, 0px 16px 40px ${colors.black200}`,

  // Used 1 time
  white40ToWhite20ToWhite40BlurComposed: `0px 0px 6px 2px ${colors.white400}, 0px 0px 10px 8px ${colors.white200}, 0px 0px 0px 1px ${colors.white400}`,
  // Used 1 time
  white10SpreadInset: `inset 0 0 0 1px ${colors.white100}`,

  // Used 2 times
  purpleSpreadSmall: `0px 0px 0px 1px ${colors.neutral700}`,
  // Used 1 time
  pufpleSpreadSmallAlt: `inset 0 0 0 1px ${colors.neutral600}`,
  // Used 4 times
  purple600BlurMedium: `0px 0px 12px ${colors.purple600}`,
  // Used 4 times
  purple600BlurSmall: `0px 0px 8px ${colors.purple600}`,
  // Used 2 times
  purple700BottomBlurMedium: `0 2px 10px -2px ${colors.purple700}`,
  // Used 1 time
  purple800A02BottomBlurMedium: `0 0 10px 5px ${rgba(colors.purple800, 0.2)}`,

  // Used 1 time
  mauve200BottomSmallInset: `inset ${colors.mauve200} 0 1px`,
  // Used 2 times
  mauve400BottomSmall: `0px 0.5px 0px ${colors.mauve400}`,
  // Used 1 time
  mauve400RightSmall: `0.5px 0px 0px ${colors.mauve400}`,
  // Used 1 time
  mauve400TopSmallInset: `inset 0px -0.5px 0px ${colors.mauve400}`,
  // Used 1 time
  mauve650BottomInset: `inset ${colors.mauve650} 0 1px`,
  // Used 1 time
  mauve650TopInset: `inset ${colors.mauve650} 0px -1px`,
  // Used 1 time
  mauve650VerticalInset: `inset ${colors.mauve650} 0px -1px, inset ${colors.mauve650} 0 1px`,
  // Used 2 times
  mauve800A024BottomBlurSmall: `0px 1px 2px ${rgba(colors.neutral900, 0.4)}`,
  // Used 1 time
  mauve800A06BlurHuge: `0px 0px 0px 4000px ${rgba(colors.neutral700, 0.8)}`,
  // Used 1 time
  mauve800A24BottomBlurSmall: `0px 1px 2px ${rgba(colors.neutral900, 0.4)}`,

  // Used 1 time
  rhino200BlurSmall: `0 0 0 2px ${colors.rhino200}`,

  // Used 3 times
  green600BlurSmall: `0 0 0 2px ${colors.green600}`,
  // Used 1 time
  green600BlurNothing: `0 0 0 0px ${colors.green600}`,

  // Used 1 time
  gray700BlurSmall: `0 0 0 1px ${colors.gray700}`,
  // Used 1 time
  gray700BlurSmallInset: `inset 0 0 0 1px ${colors.gray700}`,
};
