import React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { VariantProps } from "@stitches/react";

import { styled, CSS, keyframes, shadows, colors } from "@puzzle/theme";
import { Close } from "@puzzle/icons";

import { InDialogProvider } from "./InDialogContext";
import { Dialog } from "./Dialog";
import { Text } from "./Text";
import { Button } from "./Button";
import { MODAL_ROOT_ID } from "./constants";
import { IS_CLIENT } from "./utils/environment";

const slideInRight = keyframes({
  from: { transform: "translateX(100%)" },
  to: { transform: "translateX(0)" },
});

const slideOutRight = keyframes({
  from: { transform: "translateX(0)" },
  to: { transform: "translateX(100%)" },
});

const slideInLeft = keyframes({
  from: { transform: "translateX(-100%)" },
  to: { transform: "translateX(0)" },
});

const slideOutLeft = keyframes({
  from: { transform: "translateX(0)" },
  to: { transform: "translateX(-100%)" },
});

const Overlay = styled(DialogPrimitive.Overlay, Dialog.styles.overlay);
const Content = styled(DialogPrimitive.Content, {
  outline: "none",
  position: "fixed",
  top: 0,
  bottom: 0,
  width: "600px",
  backgroundColor: colors.neutral900,
  // TODO half-pixel not always visible on safari
  boxShadow: shadows.mauve400RightSmall,
  color: "$white",
  padding: "$3",

  defaultVariants: {
    side: "right",
  },

  variants: {
    side: {
      left: {
        left: 0,
        borderRight: "1px solid $rhino600",
        '&[data-state="open"]': { animationName: `${slideInLeft}` },
        '&[data-state="closed"]': { animationName: `${slideOutLeft}` },
      },

      right: {
        right: 0,
        borderLeft: "1px solid $rhino600",
        '&[data-state="open"]': { animationName: `${slideInRight}` },
        '&[data-state="closed"]': { animationName: `${slideOutRight}` },
      },
    },
  },

  '&[data-state="open"]': {
    animation: `300ms cubic-bezier(0.4, 0, 0.2, 1)`,
  },

  '&[data-state="closed"]': {
    animation: `300ms cubic-bezier(0.4, 0, 0.2, 1)`,
  },
});
const DialogTitle = styled(DialogPrimitive.DialogTitle, {
  margin: 0,
  paddingBottom: "$1h",
});

export type DrawerProps = React.ComponentProps<typeof DialogPrimitive.Root> &
  React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof Content> & {
    css?: CSS;
    dark?: boolean;
    showCloseButton?: boolean;
  };

export const Drawer = Object.assign(
  function Drawer({
    children,
    css,
    side,
    // Pass in title to use accessible Dialog Header
    title,
    className,
    showCloseButton,
    dark = true,
    ...props
  }: DrawerProps) {
    const modalRoot = IS_CLIENT ? document.getElementById(MODAL_ROOT_ID) : null;

    return (
      <InDialogProvider value={dark}>
        <DialogPrimitive.Root modal {...props}>
          <DialogPrimitive.Portal container={modalRoot}>
            <Overlay />
            <Content side={side} css={css} className={className}>
              {title && (
                <DialogTitle>
                  <Text variant="headingM" weight="normal" color="gray300">
                    {title}
                  </Text>
                </DialogTitle>
              )}
              {children}
              {showCloseButton && (
                <Dialog.Close asChild>
                  <Button
                    variant="minimal"
                    size="mini"
                    css={{ position: "absolute", top: "$1h", right: "$1h" }}
                  >
                    <span className="sr-only">Close</span>
                    <Close fill="currentColor" />
                  </Button>
                </Dialog.Close>
              )}
            </Content>
          </DialogPrimitive.Portal>
        </DialogPrimitive.Root>
      </InDialogProvider>
    );
  },
  {
    Content,
  }
);

Drawer.toString = Content.toString;
