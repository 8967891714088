/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import * as Stitches from "@stitches/react";
import { CaretDown, ChevronDown, Clear } from "@puzzle/icons";
import { styled, CSS, colors } from "@puzzle/theme";
import { Root, Content as DropdownContent } from "./Input";
import { Menu } from "../Menu/Menu";
import { useFieldContext } from "./Field";
import { IconButton } from "../Button";

type Option = {
  label?: string; // The label of the option in the expanded dropdown
  dropdownLabel?: string; // The label at the top of the dropdown when this option is selected
  value: string;
  disabled?: boolean;
  prefix?: React.ReactNode;
  includeSeparator?: boolean;
};

type SelectProps = Stitches.VariantProps<typeof Root> & {
  options: Option[];
  onSelectionChange: (value: string) => void;
  value?: string;
  instruction?: string;
  placeholder?: string;
  footer?: React.ReactNode;
  css?: CSS;
  optionCss?: CSS;
  menuCss?: CSS;
  variant?: "default" | "filter" | "fill";
  onClear?: () => void;
  tabIndex?: number;
  disabled?: boolean;
  clearIcon?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
};

const DropdownRoot = styled(Root, {
  cursor: "pointer",

  variants: {
    variant: {
      default: {},
      filter: {
        width: "fit-content",
        fontWeight: "$bold",
      },
      fill: {
        backgroundColor: colors.white50,
        border: "1px solid transparent",
        borderRadius: "6px",
      },
    },

    muted: {
      true: {
        color: "$gray500",
      },
      false: {},
    },
    disabled: {
      true: {
        pointerEvents: "none",
      },

      false: {},
    },
  },
});

// Consider consolidating the dropdown/clear icons in MenuButton?
const Dropdown = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<
    Stitches.VariantProps<typeof Root> & Stitches.VariantProps<typeof DropdownRoot>
  > &
    Pick<SelectProps, "variant" | "onClear" | "value" | "tabIndex" | "disabled" | "clearIcon">
>(({ children, variant = "default", value, onClear, disabled, clearIcon, ...props }, ref) => {
  const suffix = useMemo(() => {
    if (value && onClear) {
      const clear = (e: Pick<React.PointerEvent, "preventDefault" | "stopPropagation">) => {
        e.preventDefault();
        e.stopPropagation();
        onClear();
      };

      return (
        <IconButton onKeyDown={clear} onPointerDown={clear} onClick={clear} size="small">
          {clearIcon ? clearIcon : <Clear />}
        </IconButton>
      );
    } else if (variant === "filter") {
      return <CaretDown color="currentColor" />;
    }

    return <ChevronDown fill="currentColor" />;
  }, [onClear, value, variant, clearIcon]);

  return (
    <DropdownRoot {...props} role="button" variant={variant} ref={ref} disabled={disabled}>
      <DropdownContent>{children}</DropdownContent>
      {suffix}
    </DropdownRoot>
  );
});

const Content = styled("div", {
  display: "flex",
  flexDirection: "column",
  maxHeight: "250px",
  overflowY: "auto",
  "& > *": {
    flexShrink: 0,
  },
});

// This isn't a real Select in terms of accessibility and form handling.
// Radix released one: https://www.radix-ui.com/docs/primitives/components/select
// It can only overlay instead of anchoring underneath, and is very similar to Mac's select, so I need to ask Justin if it's acceptable.
// It also doesn't support animations yet.
// However, sometimes we have an "Add more" link at the bottom. This isn't real Select behavior, unless you handle a custom value.
// Once we differentiate, consider renaming this to Dropdown/SelectMenu or something.

// Also, in either case, we can't detect the width of the trigger element. That would need to be hardcoded.
export const Select = React.forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      instruction,
      options,
      value,
      onSelectionChange,
      footer,
      placeholder = "Select",
      variant = "default",
      disabled = false,
      menuCss = {},
      optionCss = {},
      clearIcon,
      onClick,
      ...props
    },
    ref
  ) => {
    const fieldContext = useFieldContext();
    const selectedOption = useMemo(() => options.find((x) => x.value === value), [options, value]);

    return (
      <Menu
        // modal={false}
        // portalled={false}
        css={menuCss}
        onClick={(e) => onClick?.(e)}
        trigger={
          <Dropdown
            {...props}
            feedback={fieldContext.feedback || props.feedback}
            ref={ref}
            variant={variant}
            muted={!value}
            value={value}
            disabled={disabled}
            tabIndex={0}
            clearIcon={clearIcon}
          >
            {selectedOption?.dropdownLabel || selectedOption?.label || value || placeholder || ""}
          </Dropdown>
        }
        side="bottom"
        label={instruction}
      >
        {options.length > 0 && (
          <Content>
            <Menu.Group>
              <Menu.RadioGroup value={value} onValueChange={onSelectionChange}>
                {options.map(({ value, label, disabled, prefix, includeSeparator }) => (
                  <React.Fragment key={value}>
                    {includeSeparator && (
                      <Menu.Group>
                        <Menu.Separator />
                      </Menu.Group>
                    )}
                    <Menu.RadioItem value={value} key={value} disabled={disabled} css={optionCss}>
                      {prefix && prefix}
                      {label || value}
                    </Menu.RadioItem>
                  </React.Fragment>
                ))}
              </Menu.RadioGroup>
            </Menu.Group>

            {footer && (
              <>
                <Menu.Separator />
                <Menu.Group>{footer}</Menu.Group>
              </>
            )}
          </Content>
        )}
      </Menu>
    );
  }
);

Select.toString = Root.toString;
