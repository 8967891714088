import capitalize from "lodash/capitalize";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";
import min from "lodash/min";
import max from "lodash/max";
import uniqBy from "lodash/uniqBy";
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { Granularity } from "@react-types/datepicker";

import { Check } from "@puzzle/icons";
import { endOfCalendarDate, isSameDateValue } from "@puzzle/utils";

import { styled, CSS } from "@puzzle/theme";
import { Calendar } from "./Calendar";
import { CalendarView, GroupBy, Modifiers, RangePreset, RangePresetKey } from "./types";
import { isRangeLengthValid, mergeModifiers } from "./utils";
import { KeyboardDateInput } from "../form/DateInput";
import { Text } from "../Text";
import {
  CalendarDate,
  getLocalTimeZone,
  parseDate,
  toCalendarDate,
  today,
} from "@internationalized/date";

export type AllowEmpty = [boolean, boolean];
export type RangeValue<Empty extends AllowEmpty = [false, false], T = CalendarDate> = [
  Empty[0] extends false ? T : T | null | undefined,
  Empty[1] extends false ? T : T | null | undefined,
];

const getInputRangeValidity = (inputRange: RangeValue<[true, true]>, allowEmpty?: AllowEmpty) => {
  const isStartValid = inputRange?.[0] || allowEmpty?.[0];
  const isEndValid = inputRange?.[1] || allowEmpty?.[1];
  if (!isStartValid || !isEndValid) {
    return [isStartValid, isEndValid];
  }

  if (inputRange[0] && inputRange[1] && inputRange[0].compare(inputRange[1]) > 0) {
    return [false, false];
  }

  return [true, true];
};

const getCustomKey = (view: `${CalendarView}`) => {
  return `custom${capitalize(view)}` as RangePresetKey;
};
const getCustomLabel = (view: `${CalendarView}`, views: CalendarView[]) =>
  views.length === 1 ? "Custom" : `Custom ${view.toLowerCase()}s`;

const Inputs = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$1h",
  padding: "$2",
  borderBottom: "1px solid $mauve680",
});

const Container = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  background: "$rhino700",

  [`${Calendar}`]: {
    padding: "$2",
  },
});

const Body = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const PresetSidebar = styled(RadioGroupPrimitive.RadioGroup, {
  display: "flex",
  flexDirection: "column",
  borderRight: "1px solid $mauve680",
  padding: "$0h 0",
});

const RadioItem = styled(RadioGroupPrimitive.RadioGroupItem, {
  all: "unset",
  display: "flex",
  flexDirection: "row",
  padding: "$1 $3 $1 $1h",
  gap: "$1",
  whiteSpace: "nowrap",
  cursor: "pointer",

  fontWeight: "$bold",
  fontSize: "13px",
  lineHeight: "16px",
  color: "$gray300",

  '&[aria-checked="false"]': {
    svg: {
      opacity: 0,
    },
  },

  '&[aria-checked="true"]': {
    color: "$neutral100",
  },

  '&[aria-checked="true"], &:hover, &:focus': {
    background: "$mauve700",
  },
});

const Preset = ({
  label,
  ...props
}: React.ComponentProps<typeof RadioItem> & {
  label: string;
}) => {
  return (
    <RadioItem {...props}>
      <RadioGroupPrimitive.Indicator forceMount>
        <Check color="currentColor" size={10} />
      </RadioGroupPrimitive.Indicator>

      {label}
    </RadioItem>
  );
};

export type DateRangePickerCalendarProps<Empty extends AllowEmpty> = {
  value: RangeValue<Empty>;
  onChange?: (value: RangeValue<Empty>, preset?: RangePreset) => void;
  onDisplayedValueChange?: (value: RangeValue<[true, true]>) => void;
  minDate?: CalendarDate;
  maxDate?: CalendarDate;
  showInputs?: boolean;
  disabled?: boolean;
  // allowEmpty:

  // TODO Not used yet, but make sure these work
  minLength?: number;
  maxLength?: number;

  modifiers?: Modifiers;
  view?: `${CalendarView}`;
  presets?: RangePreset[];
  preset?: RangePreset;

  // TODO There's no clearing UI yet
  allowEmpty?: Empty;

  inferCustomPresets?: boolean;
  // Possibly deprecate? Separately updating the preset and start/end isn't great.
  onPresetChange?: (preset: RangePreset) => void;

  dark?: boolean;
  css?: CSS;
};

enum FocusedDateStates {
  Start = "start",
  End = "end",
}

const dateInputDefaultCss = { width: 112 };

export function DateRangePickerCalendar<
  // Don't supply this generic! It will infer from allowEmpty={[false, false]}
  Empty extends AllowEmpty = [false, false],
>({
  value,
  onChange,

  // Helpful for displaying the preview values.
  onDisplayedValueChange,

  view: _view,
  modifiers: receivedModifiers,

  minDate,
  maxDate,
  minLength = 0,
  maxLength,
  showInputs,
  allowEmpty,
  inferCustomPresets = true,

  presets: _presets,
  preset: _preset,
  onPresetChange: _onPresetChange,

  dark,
  ...props
}: DateRangePickerCalendarProps<Empty>) {
  const [internalValue, setInternalValue] = useState<Partial<RangeValue<Empty>>>(value);
  const [startDate, endDate] = internalValue;
  const [focusedDateState, setFocusedDateState] = useState<FocusedDateStates | null>(null);

  const presets = useMemo<RangePreset[] | undefined>(() => {
    if (_presets) {
      // This adds "Custom" options that let you use different views.
      // It's based on the currently available presets.
      const views = compact(uniq(_presets.map((x) => x.view)));
      const groupBys = compact(uniq(_presets.map((x) => x.groupBy)));
      const customPresets = inferCustomPresets
        ? views.map((view) => ({
            key: getCustomKey(view),
            label: getCustomLabel(view, views),
            view,
            groupBy:
              view === CalendarView.Day || isEqual(groupBys, [GroupBy.Total])
                ? GroupBy.Total
                : (view as unknown as GroupBy),
          }))
        : [];

      // TODO hard to set customMonth from the outside
      return uniqBy(compact([..._presets, ...customPresets, _preset]), "key");
    }

    return undefined;
  }, [_presets, inferCustomPresets, _preset]);

  const [presetState, setPresetState] = useState<RangePreset | undefined>(() => {
    if (!_preset && _view) {
      // TODO feels hacky
      const preset = presets?.find((p) => _view && p.key === getCustomKey(_view));
      if (preset) {
        return preset;
      }
    }

    return _preset;
  });
  const preset = _preset ?? presetState;
  const view = preset?.view || _view || "day";
  const unit = view;

  const setPreset = useCallback(
    (key: string, newRangeValue?: RangeValue<Empty>) => {
      const preset = presets?.find((p) => p.key === key);
      if (preset) {
        const set = _onPresetChange ?? setPresetState;
        const finalValue = newRangeValue ?? preset.range?.() ?? value;
        onChange?.(finalValue, preset);
        set(preset);
      }
    },
    [_onPresetChange, onChange, presets, value]
  );

  const [hoveredDate, setHoveredDate] = useState<CalendarDate | null>();
  const [nextActionWillCompleteRange, setNextActionWillCompleteRange] = useState(false);

  const getPossibleStartDate = useCallback(
    (date: CalendarDate) => {
      return parseDate(
        min(compact([date, startDate ?? undefined]).map((x) => x.toString())) as string
      );
    },
    [startDate]
  );

  const getPossibleEndDate = useCallback(
    (date: CalendarDate) => {
      if (!nextActionWillCompleteRange && endDate) {
        return endDate;
      }

      return parseDate(
        max(
          compact([date, endDate ?? undefined, startDate ?? undefined]).map((x) => x.toString())
        ) as string
      );
    },
    [endDate, nextActionWillCompleteRange, startDate]
  );

  // NOTE: This is not one range array. With useMemo, it'd create new object references.
  // useState is safer since you have control over updating.
  const displayedStartDate = useMemo(
    () => (!hoveredDate || endDate ? startDate || value[0] : getPossibleStartDate(hoveredDate)),
    [endDate, getPossibleStartDate, hoveredDate, startDate, value]
  );
  const displayedEndDate = useMemo(
    () => (!hoveredDate ? endDate || value[1] : getPossibleEndDate(hoveredDate)),
    [endDate, getPossibleEndDate, hoveredDate, value]
  );

  const updatingInputRef = useRef(false);
  const [inputRange, setInputRange] = useState<RangeValue<[true, true]>>([
    displayedStartDate,
    displayedEndDate,
  ]);
  const [inputRangeValidity, setInputRangeValidity] = useState(() =>
    getInputRangeValidity(inputRange, allowEmpty)
  );

  const isSameDate = useCallback(
    (dateA: CalendarDate, dateB: CalendarDate) => isSameDateValue(dateA, dateB, unit),
    [unit]
  );

  const handleInputChange = useCallback(() => {
    let isChange =
      !!inputRange[0] !== !!displayedStartDate || !!inputRange[1] !== !!displayedEndDate;
    if (inputRange[0] && displayedStartDate && !isSameDate(inputRange[0], displayedStartDate)) {
      isChange = true;
    }
    if (inputRange[1] && displayedEndDate && !isSameDate(inputRange[1], displayedEndDate)) {
      isChange = true;
    }
    if (!isChange) {
      // this function is triggered throughout the component at times when there
      // are no actual changes to the ranges
      // if this is the case we can just return
      // onChange callback functions don't need to know about these changes
      // and this check additionally prevents unintended changes from a preset to custom
      // it might make sense to apply this to any place the onChange prop is called,
      // but for now I don't see any issues related to those calls
      return;
    }

    const validity = getInputRangeValidity(inputRange, allowEmpty);
    setInputRangeValidity(validity);

    if (validity.every(Boolean)) {
      updatingInputRef.current = true;
      const newPreset = presets?.find((p) => p.key.startsWith("custom") && p.view === view);
      onChange?.(inputRange as RangeValue<Empty>, newPreset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowEmpty, inputRange, onChange, presets, view]);

  useEffect(() => {
    if (updatingInputRef.current) {
      updatingInputRef.current = false;
      return;
    }

    const potentialRange: RangeValue<[true, true]> = [displayedStartDate, displayedEndDate];
    setInputRange((x) => {
      if (isEqual(potentialRange, x)) {
        return x;
      }

      return potentialRange;
    });

    const validity = getInputRangeValidity(potentialRange, allowEmpty);
    setInputRangeValidity(validity);
  }, [displayedStartDate, displayedEndDate, allowEmpty]);

  useEffect(() => {
    onDisplayedValueChange?.([displayedStartDate, displayedEndDate]);
  }, [displayedStartDate, displayedEndDate, onDisplayedValueChange]);

  const isStartDate = useCallback(
    (date: CalendarDate) =>
      Boolean(
        displayedStartDate &&
          isSameDate(date, displayedStartDate) &&
          ((nextActionWillCompleteRange &&
            (!displayedEndDate || isSameDate(date, displayedEndDate))) ||
            (displayedEndDate && date.compare(endOfCalendarDate(displayedEndDate, unit)) < 0))
      ),
    [displayedEndDate, displayedStartDate, isSameDate, nextActionWillCompleteRange, unit]
  );
  const isMiddleDate = useCallback(
    (date: CalendarDate) =>
      Boolean(
        displayedStartDate &&
          displayedEndDate &&
          date.compare(displayedStartDate) > 0 &&
          date.compare(displayedEndDate) < 0
      ),
    [displayedEndDate, displayedStartDate]
  );
  const isEndDate = useCallback(
    (date: CalendarDate) =>
      Boolean(displayedStartDate && displayedEndDate && isSameDate(date, displayedEndDate)),
    [displayedEndDate, displayedStartDate, isSameDate]
  );

  const modifiers = useMemo<Modifiers>(
    () =>
      mergeModifiers(
        {
          selected: (date) =>
            isStartDate(date) ||
            isMiddleDate(date) ||
            isEndDate(date) ||
            Boolean(startDate && isSameDateValue(date, startDate, view)),
          selectedStart: isStartDate,
          selectedMiddle: isMiddleDate,
          selectedEnd: isEndDate,
        },
        receivedModifiers
      ),
    [isEndDate, isMiddleDate, isStartDate, receivedModifiers, startDate, view]
  );

  const [initialDate, setInitialDate] = useState(() => endDate ?? today(getLocalTimeZone()));
  const [nextInitialDate, setNextInitialDate] = useState<CalendarDate | undefined>(undefined);

  useEffect(() => {
    setInternalValue(value);

    if (nextInitialDate) {
      setInitialDate(nextInitialDate);
      setNextInitialDate(undefined);
    } else if (value[1]) {
      setInitialDate(value[1]);
    }
  }, [nextInitialDate, value]);

  const handleSelectDate = useCallback(
    (date: CalendarDate) => {
      const completeRangeSelection = (value: RangeValue) => {
        setNextInitialDate(value[1]);
        const preset = presets?.find((p) => p.key.startsWith("custom") && p.view === view);
        onChange?.(value, preset);
        if (preset) {
          setPreset(preset.key, value);
        }
        setNextActionWillCompleteRange(false);
      };

      if (focusedDateState) {
        // if one of the date fields is focused we need to build the new
        // range and check the validity depedant on which field was
        // focused
        const nextRange = [startDate, endDate] as RangeValue<[false, false]>;
        if (focusedDateState === FocusedDateStates.Start) {
          nextRange[0] = date;
          const validity = getInputRangeValidity(nextRange);
          if (!validity.every(Boolean)) {
            setInputRangeValidity([false, true]);
            return;
          }
        } else if (focusedDateState === FocusedDateStates.End) {
          nextRange[1] = date;
          const validity = getInputRangeValidity(nextRange);
          if (!validity.every(Boolean) || !startDate) {
            setInputRangeValidity([true, false]);
            return;
          }
        }

        // if the new range is valid we want to
        // set the range validity as valid, valid
        // unset the field focus, complete the range
        // selection logic (calling the parent component change callback)
        // and set the range to be displayed on the picker
        setInputRangeValidity([true, true]);
        setFocusedDateState(null);
        completeRangeSelection(nextRange);
        setInputRange(nextRange);
        return;
      }

      if (nextActionWillCompleteRange) {
        const newStartDate = getPossibleStartDate(date);
        const newEndDate = getPossibleEndDate(date);

        // TODO don't show this as selectable
        const invalid =
          newEndDate &&
          !isRangeLengthValid(
            { startDate: newStartDate, endDate: newEndDate },
            { minLength, maxLength }
          );

        if (invalid || !newEndDate) {
          return;
        }

        if (newEndDate) {
          const value: RangeValue<[false, false]> = [newStartDate, newEndDate];
          completeRangeSelection(value);
        }
      } else {
        setInternalValue([date, undefined]);
        setNextActionWillCompleteRange(true);
      }
    },
    [
      getPossibleEndDate,
      getPossibleStartDate,
      nextActionWillCompleteRange,
      maxLength,
      minLength,
      onChange,
      presets,
      setPreset,
      setNextActionWillCompleteRange,
      view,
      focusedDateState,
      startDate,
      endDate,
    ]
  );

  return (
    <Container {...props}>
      {presets && presets.length > 0 && (
        <PresetSidebar value={preset?.key} onValueChange={setPreset}>
          {presets.map((p) => (
            <Preset autoFocus={p.key === preset?.key} key={p.key} value={p.key} label={p.label} />
          ))}
        </PresetSidebar>
      )}

      <Body>
        {showInputs &&
          // TODO disabled quarter pickers since input can't support that
          // We can potentially round user input?
          (view === "day" || view === "month" || view === "year") && (
            <Inputs>
              <KeyboardDateInput
                aria-label="Start date"
                granularity={view.toString() as Granularity}
                value={inputRange[0]}
                onChange={(date) => {
                  setInputRange(([_, end]) => [date && toCalendarDate(date), end]);
                }}
                onFocus={() => {
                  setFocusedDateState(FocusedDateStates.Start);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleInputChange();
                    e.preventDefault();
                  }
                }}
                onBlur={handleInputChange}
                feedback={!inputRangeValidity[0] ? "negative" : undefined}
                size="mini"
                css={dateInputDefaultCss}
              />
              <Text color="#565675" variant="bodyS">
                to
              </Text>
              <KeyboardDateInput
                aria-label="End date"
                granularity={view.toString() as Granularity}
                value={inputRange[1]}
                onFocus={() => {
                  setFocusedDateState(FocusedDateStates.End);
                }}
                onChange={(date) => {
                  setInputRange(([start, _]) => [
                    start,
                    date && endOfCalendarDate(toCalendarDate(date), view),
                  ]);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleInputChange();
                    e.preventDefault();
                  }
                }}
                onBlur={handleInputChange}
                feedback={!inputRangeValidity[1] ? "negative" : undefined}
                size="mini"
                css={dateInputDefaultCss}
              />
            </Inputs>
          )}

        <Calendar
          initialDate={initialDate}
          view={view}
          minDate={minDate}
          maxDate={maxDate}
          modifiers={modifiers}
          onCellHover={setHoveredDate}
          onCellClick={handleSelectDate}
          dark={dark}
          isPermanent
        />
      </Body>
    </Container>
  );
}
