/**
 * NOTE: This requires a masking color. It'd take a bit more work to draw the lines correctly without it.
 * This was first made for drawers. You can replace the mask color with <Timeline css={{ $$maskColor: "#fff "}} />
 */

import React from "react";
import { Avatar as AvatarBase } from "./Avatar";
import { Box } from "./Box";
import { Text } from "./Text";
import { Stack } from "./Stack";
import { parseAbsoluteToLocal } from "@internationalized/date";
import { differenceInDays } from "date-fns/differenceInDays";
import { formatDistanceToNowStrict } from "date-fns/formatDistanceToNowStrict";
import { styled, colors } from "@puzzle/theme";
import { useDateFormatter } from "@react-aria/i18n";
import { Slack } from "@puzzle/icons";

enum CommunicationMedium {
  Dashboard = "Dashboard",
  Email = "Email",
  Slack = "Slack",
}
type TimelineItemType = "line" | "comment" | "comment2";
type Author = { name?: string | null; email?: string | null };
type TimelineItem = {
  /**
   * Optional icon to show on the timeline.
   */
  icon?: React.ReactNode;
  /**
   * Date of the activity as ISO datetime string
   */
  date: string;
  /**
   * Message contents.
   */
  body: React.ReactNode;
  /**
   * Additional subtext to show next to the date. Only supported for "line" type.
   */
  subtext?: React.ReactNode;
  /**
   * User who initiated the activity.
   */
  author?: React.ReactElement | Author | null;
  /**
   * Line - Standard behavior.
   * Comment - Boxes content to look like a comment.
   */
  type?: TimelineItemType;

  /**
   * Slack/email etc
   */
  viaMedium?: CommunicationMedium;
};

type RenderedTimelineItem = Omit<TimelineItem, "type">;

const Dot = styled("div", {
  width: 6,
  height: 6,
  borderRadius: "$ellipse",
  border: "1px solid $rhino600",
  backgroundColor: "$$maskColor",
});

const useRelativeFormatter = () => {
  const dateFormatter = useDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return (input: string) => {
    const date = parseAbsoluteToLocal(input).toDate();
    if (differenceInDays(new Date(), date) > 7) {
      return dateFormatter.format(date);
    }

    // TODO explore Intl.RelativeTimeFormat?
    // Also, use a timeout to periodically update if it's less than an hour?
    const result = `${formatDistanceToNowStrict(date)} ago`;
    return result === "0 seconds ago" ? "Just now" : result;
  };
};

const isAuthorObject = (obj: React.ReactElement | Author): obj is Author => {
  return !React.isValidElement(obj);
};

const logoForMedium = (medium: CommunicationMedium) => {
  switch (medium) {
    case CommunicationMedium.Slack:
      return <Slack />;
    default:
      return null;
  }
};

// NOTE: This currently displays in local time, which may actually be preferrable?
const ItemComment = ({ date, body, author, viaMedium }: RenderedTimelineItem) => {
  const formatDate = useRelativeFormatter();

  return (
    <Box
      css={{
        flex: 1,
        background: colors.mauve900,
        padding: "$1 $1h $1h",
        borderRadius: "$1",
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
      }}
    >
      <Stack gap="1">
        <Stack
          gap="1"
          direction="horizontal"
          css={{
            alignItems: "center",
          }}
        >
          <Text size="bodyS" color="neutral100">
            {author === null || author === undefined
              ? "User"
              : !isAuthorObject(author)
                ? author
                : author?.name
                  ? author.name
                  : author.email
                    ? author.email
                    : "User"}
          </Text>
          <Text size="bodyXS" color="gray400" css={{ lineHeight: 1 }}>
            {formatDate(date)}
          </Text>
          {viaMedium ? (
            <Text
              size="bodyXS"
              color="gray400"
              css={{ lineHeight: 1, display: "flex", alignContent: "center", gap: "$0h" }}
            >
              &#x2022; via{logoForMedium(viaMedium) ? logoForMedium(viaMedium) : ""} {viaMedium}
            </Text>
          ) : null}
        </Stack>
        <Text
          as="div"
          size="body"
          lineHeight="bodyS"
          color="gray300"
          css={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "flex",
            flexDirection: "column",
            gap: "$1",

            p: {
              margin: 0,
            },
            a: {
              color: "$neutral100",
              textDecoration: "underline",
            },
          }}
        >
          {body}
        </Text>
      </Stack>
    </Box>
  );
};

const ItemComment2 = ({ date, body, author, viaMedium }: RenderedTimelineItem) => {
  const formatDate = useRelativeFormatter();
  const userString = (author && isAuthorObject(author) && author?.name) || "User"; // !@#$!@#$ crazymaking

  return (
    <Box
      css={{
        flex: 1,
        background: "#373548",
        padding: "$2 $1h $1h",
        borderRadius: "$1",
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
      }}
    >
      <Stack gap="1h">
        <Stack
          gap="1"
          direction="horizontal"
          css={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div style={{ width: "20px", height: "20px" }}>
            <Avatar user={{ name: userString! }} />
          </div>

          <Text size="bodyS" color="neutral100" weight="bold">
            {author === null || author === undefined
              ? "User"
              : !isAuthorObject(author)
                ? author
                : author?.name
                  ? author.name
                  : author.email
                    ? author.email
                    : "User"}
          </Text>

          <Text size="bodyXS" color="gray400" css={{ lineHeight: 1, marginLeft: "auto" }}>
            {formatDate(date)}
          </Text>
          {viaMedium ? (
            <Text
              size="bodyXS"
              color="gray600"
              css={{ lineHeight: 1, display: "flex", alignContent: "center", gap: "$0h" }}
            >
              &#x2022; via{logoForMedium(viaMedium) ? logoForMedium(viaMedium) : ""} {viaMedium}
            </Text>
          ) : null}
        </Stack>
        <Text
          as="div"
          size="body"
          lineHeight="bodyS"
          color="gray300"
          css={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "flex",
            flexDirection: "column",
            gap: "$1",

            p: {
              margin: 0,
            },
            a: {
              color: "$neutral100",
              textDecoration: "underline",
            },
          }}
        >
          {body}
        </Text>
      </Stack>
    </Box>
  );
};

const ItemLine = ({ date, body, subtext }: RenderedTimelineItem) => {
  const formatDate = useRelativeFormatter();

  const renderedDate = (
    <Text size="bodyXS" color="gray400" css={{ display: "inline-block" }}>
      {formatDate(date)}
    </Text>
  );
  return (
    <div>
      <Text
        size="bodyS"
        color="gray300"
        weight="bold"
        css={{
          em: {
            fontStyle: "initial",
            color: "$neutral100",
          },
        }}
      >
        {body}
      </Text>

      {subtext ? (
        <Stack gap="1" direction="horizontal" css={{ marginTop: "$0h" }}>
          {renderedDate}

          <Box
            css={{
              color: "$blue300",
              fontSize: "$bodyS",
            }}
          >
            {subtext}
          </Box>
        </Stack>
      ) : (
        <>
          {/* Got this idea from Linear. Trust me. margin-left is bad if it overflows to its own line. */}
          <Box css={{ display: "inline-block", width: "$space$1" }} />

          {renderedDate}
        </>
      )}
    </div>
  );
};

const Root = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$1h",
  position: "relative",

  $$maskColor: "transparent",

  "&::before": {
    zIndex: -1,
    content: "",
    position: "absolute",
    top: 10,
    right: 0,
    bottom: 0,
    left: 0,
    // only safe way  to subpixel on non-retina screens
    transform: "translateX(9.5px)",
    width: 1,
    background: "#3A3A50",
  },
});

const ItemRoot = styled("div", {
  display: "flex",
  position: "relative",
  gap: "$1h",

  // removes the bottom part of the line via mask
  "&:last-child": {
    "&::before": {
      zIndex: -1,
      content: "",
      position: "absolute",
      top: 10,
      right: 0,
      bottom: 0,
      left: 0,
      width: 20,
      background: colors.neutral700,
    },
  },
});

const ItemIcon = styled("div", {
  width: 20,
  height: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,
  color: "$gray500",

  defaultVariants: {
    maskBackground: false,
  },

  variants: {
    maskBackground: {
      true: {
        background: "$$maskColor",
      },
      false: {},
    },
  },
});

const TimelineItemTypeToComponent: Record<
  TimelineItemType,
  (props: RenderedTimelineItem) => React.ReactNode
> = {
  line: ItemLine,
  comment: ItemComment,
  comment2: ItemComment2,
};

const Item = (
  props: TimelineItem &
    Pick<React.HTMLAttributes<HTMLDivElement>, "onMouseEnter" | "onMouseLeave" | "onMouseMove">
) => {
  const { icon, type = "line", onMouseEnter, onMouseLeave, onMouseMove } = props;
  const Body = TimelineItemTypeToComponent[type];

  return (
    <ItemRoot onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onMouseMove={onMouseMove}>
      {type !== "comment2" && <ItemIcon maskBackground={Boolean(icon)}>{icon || <Dot />}</ItemIcon>}
      <Body {...props} />
    </ItemRoot>
  );
};

const Avatar = (props: React.ComponentProps<typeof AvatarBase>) => {
  return <AvatarBase {...props} size="mini" />;
};

export const Timeline = Object.assign(Root, {
  Item,
  Avatar,
});
