import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
/* eslint-disable react/display-name */
import React, { InputHTMLAttributes, useCallback, useMemo, useRef } from "react";
import { CSSProps, colors, styled } from "@puzzle/theme";
import { IconButton } from "../Button";
import { useFieldContext } from "./Field";
import { useComposedRefs } from "@radix-ui/react-compose-refs";
import { VariantProps } from "@stitches/react";
import { Clear } from "@puzzle/icons";
const Addon = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "$0h",
  alignItems: "center",
  color: "$gray500",
});

export const Prefix = styled(Addon, {});
export const Suffix = styled(Addon, {});
// Only use this with Root when you don't want a real input.
// This input-like container should probably be extracted.
export const Content = styled("div", {
  flex: 1,
  textAlign: "left",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
});

export const Root = styled("div", {
  appearance: "none",
  outline: "none",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: "$1",
  alignItems: "center",

  background: "transparent",
  border: "1px solid",
  borderColor: "$$borderColor",

  '[role="dialog"] &': {
    $$borderColor: "$colors$rhino600",
  },

  $$boxShadowColor: "transparent",
  borderRadius: "4px",

  color: "$gray50",
  textStyle: "$headingS",
  fontWeight: "$normal",

  transition: "0.15s all ease-out",

  // TODO export a component for this?
  "input, .input": {
    textAlign: "left",
    appearance: "none",
    background: "none",
    border: "none",
    color: "inherit",
    font: "inherit",
    outline: "none",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
    padding: 0,
  },

  defaultVariants: {
    size: "medium",
    disabled: false,
    readOnly: false,
    feedback: undefined,
    empty: true,
    hideInitialBorder: false,
    hideLeftBorder: false,
  },

  variants: {
    hideInitialBorder: {
      false: {
        $$borderColor: "$colors$gray700",
      },

      true: {
        $$borderColor: "transparent",
      },
    },

    empty: {
      true: {},
      false: {},
    },

    size: {
      none: {
        padding: 0,
        lineHeight: 1,
      },

      compact: {
        padding: "0 $1",

        [`${Content}, .input`]: {
          padding: "6px 0",
        },
      },

      mini: {
        padding: "0 $1",
        lineHeight: 1,

        [`${Content}, .input`]: {
          padding: "$1 0",
        },
      },

      small: {
        padding: "0 $1h",
        lineHeight: 1,

        [`${Content}, .input`]: {
          padding: "$1 0",
        },
      },

      medium: {
        padding: "0 $1h",

        [`${Content}, .input`]: {
          padding: "$1h 0",
        },
      },

      large: {
        padding: "0 $1h",

        [`${Content}, input`]: {
          padding: "$2 0",
        },
      },
    },

    feedback: {
      positive: {
        $$borderColor: "$colors$green600",
        $$boxShadowColor: "$$borderColor",
      },
      negative: {
        $$borderColor: "$colors$red600 !important",
        $$boxShadowColor: "$$borderColor",
        backgroundColor: colors.black25,
      },
      neutral: {},
      undefined: {},
    },

    disabled: {
      true: {
        color: "$gray600",
        $$borderColor: "$colors$gray600",
        pointerEvents: "none",
      },

      false: {
        "input::placeholder": {
          color: "$gray500",
        },
      },
    },

    readOnly: {
      true: {
        "&:hover": {
          $$borderColor: "$colors$rhino600 !important",
        },
        "&:focus-within": {
          $$borderColor: "$colors$rhino600 !important",
        },
      },
      false: {},
    },

    focused: {
      true: {
        $$borderColor: "$colors$gray300",
      },
      false: {},
    },

    hideLeftBorder: {
      true: {
        borderLeft: "none",
        borderColor: "$mauve400",
        borderRadius: "0px 4px 4px 0px",
      },
      false: {},
    },
  },

  compoundVariants: [
    {
      disabled: false,
      feedback: undefined,
      css: {
        "&:hover": {
          $$borderColor: "$colors$gray500",
        },

        "&:focus-within": {
          $$borderColor: "$colors$gray300",
        },
      },
    },
    {
      feedback: undefined,
      disabled: false,
      empty: true,
      css: {
        "&:focus-within": {
          $$boxShadowColor: "$$borderColor",
        },
      },
    },
  ],
});

export type InputVariants = Omit<VariantProps<typeof Root>, "empty" | "disabled">;
export type FormInputProps = CSSProps &
  InputVariants &
  Omit<InputHTMLAttributes<HTMLInputElement>, "size" | "prefix"> & {
    clearable?: boolean;
    onClear?: () => void;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
    InputComponent?: React.ElementType;
    input?: React.ReactElement;
    rootProps?: React.ComponentPropsWithoutRef<"div">;
    rootRef?:
      | ((instance: HTMLDivElement | null) => void)
      | React.MutableRefObject<HTMLDivElement | null>
      | null;
    textAlign?: "left" | "right";
  };

export const Input = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, FormInputProps>(
  (
    {
      size,
      feedback,
      clearable = false,
      prefix,
      suffix,
      input,
      disabled,
      readOnly,
      rootProps,
      focused,
      className,
      css,
      textAlign,
      hideInitialBorder,
      hideLeftBorder,
      rootRef,
      onClick,
      onClear,
      ...props
    },
    forwardedRef
  ) => {
    const fieldContext = useFieldContext();
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClick: React.MouseEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        if (
          inputRef.current
          // && e.currentTarget === e.target
        ) {
          inputRef.current.focus();
        }

        onClick?.(e);
      },
      [onClick]
    );

    const handleClear = useCallback(
      (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>) => {
        onClear?.();

        if (inputRef.current && props.onChange) {
          // TODO figure out cleaner way to fake these somewhat incompatible events
          // @ts-expect-error ignore
          props.onChange({
            ...e,

            target: {
              ...inputRef.current,
              value: "",
            },
          });
        }
      },
      [onClear, props]
    );

    const prefixContent = useMemo(() => {
      return prefix && <Prefix>{prefix}</Prefix>;
    }, [prefix]);

    const suffixContent = useMemo(() => {
      return (
        (suffix || clearable) && (
          <Suffix>
            {clearable && (
              <IconButton
                onClick={handleClear}
                size="small"
                tabIndex={-1}
                // Prevent onSubmit interaction
                type="button"
                css={{ visibility: props.value ? "visible" : "hidden" }}
              >
                <Clear />
              </IconButton>
            )}

            {suffix}
          </Suffix>
        )
      );
    }, [clearable, handleClear, suffix, props.value]);

    const finalInput = input || <input type="text" />;

    return (
      <Root
        size={size}
        feedback={fieldContext.feedback || feedback}
        onClick={handleClick}
        disabled={disabled}
        readOnly={readOnly}
        className={className}
        empty={!props.value}
        hideInitialBorder={hideInitialBorder}
        hideLeftBorder={hideLeftBorder}
        focused={focused}
        {...rootProps}
        css={css}
        ref={rootRef}
      >
        {prefixContent}

        {/* This was a terrible idea, prop overriding is unpredictable */}
        {React.cloneElement(
          finalInput,
          omitBy(
            {
              ref: useComposedRefs(inputRef, forwardedRef),
              disabled,
              readOnly: readOnly ?? !props.onChange,
              className: "input",
              ...props,
              onKeyDown: clearable
                ? (e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === "Escape" && clearable) {
                      handleClear(e);
                    }

                    props?.onKeyDown?.(e);
                  }
                : (e: React.KeyboardEvent<HTMLInputElement>) => props?.onKeyDown?.(e),
              style: {
                textAlign,
                ...props.style,
              },
            },
            isNil
          )
        )}

        {suffixContent}
      </Root>
    );
  }
);

// TODO Support auto-expanding?
const StyledTextArea = styled("textarea", {
  unstyled: true,
  font: "inherit",
  lineHeight: "1.2",
  color: "inherit",
  width: "100%",
  resize: "none",
});
export const TextArea = React.forwardRef<
  HTMLTextAreaElement,
  FormInputProps & React.ComponentProps<"textarea">
>(({ ...props }, ref) => {
  return <Input {...props} ref={ref} input={<StyledTextArea />} />;
});

Input.toString = Root.toString;
