/* eslint-disable react/display-name */
import React, { useEffect, useCallback, useMemo } from "react";
import { FixedSizeList } from "react-window";
import _useAutocomplete, {
  UseAutocompleteProps,
  AutocompleteGroupedOption,
} from "@mui/material/useAutocomplete";
import { styled } from "@puzzle/theme";

import { Box } from "../Box";
import { Stack } from "../Stack";
import { Text } from "../Text";
import { Checkbox } from "../Checkbox";
import { menuContentStyles, menuItemStyles, menuLabelStyles } from "../Menu/Menu";
import { useInDialogContext } from "../InDialogContext";

const LIST_PADDING = 4;

const GroupList = styled(
  "ul",
  {
    listStyle: "none",
    padding: 0,
    margin: 0,

    "& > *:first-child": {
      marginBottom: LIST_PADDING,
      marginTop: -LIST_PADDING,
    },

    "&:not(:last-child) > *:last-child": {
      marginBottom: LIST_PADDING,
    },
  },
  menuContentStyles
);

const GroupHeader = styled("li", menuLabelStyles);

const ListItemRoot = styled("li", menuItemStyles, {
  display: "flex",
  flexDirection: "row",
  gap: "$1",
  '&[aria-selected="true"]': {
    color: "$neutral100 !important",
  },
  "&.Mui-focused": {
    backgroundColor: "$mauve700",
    color: "$gray200",
  },
});

const ListItem = (props: React.ComponentProps<typeof ListItemRoot>) => {
  return <ListItemRoot showActiveBar {...props} />;
};
ListItem.toString = ListItemRoot.toString;

const ListItemLabel = styled("div", {
  textOverflow: "ellipsis",
  overflow: "hidden",
});

const Listbox = styled("ul", {
  padding: "$0h 0",
  margin: 0,
  maxHeight: 300,
  overflowY: "auto",
  listStyle: "none",
});

const WindowedListbox = styled(Listbox, {
  padding: 0,
  overflow: "hidden",
});

const WindowedOuterElement = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  (props, ref) => {
    return <div ref={ref} {...props} style={{ ...props.style, overflowX: "hidden" }} />;
  }
);

export type AutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = {
  placeholder?: string;
  disabled?: boolean;
  windowed?: boolean;
  // inputProps?: Omit<React.ComponentPropsWithoutRef<"input">, "placeholder" | "disabled" | "size">;

  // These might not be an ideal API as they rely on fake options
  getFreeSoloOption?: (value: string, options: T[]) => T;
  emptyOption?: T;
  getOptionModifiers?: (option: T) => "indent"[] | undefined;
  renderOption?: (option: T) => React.ReactNode;

  // Remove after: https://github.com/mui/material-ui/pull/32910
  // Bizarre that they implemented isOptionEqualToValue, but not this?
  getOptionKey: (option: T) => string;

  addText?: string;
  onBlur?: React.FocusEventHandler;
  css?: React.CSSProperties;
} & UseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>;

export function useAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  onBlur,
  placeholder,
  disabled = false,
  disableClearable,
  multiple,
  freeSolo,

  windowed = false,

  getFreeSoloOption,
  emptyOption,
  addText = "Add",

  getOptionKey,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const inDialog = useInDialogContext();

  const { getOptionLabel, getOptionModifiers, groupBy } = props;
  const result = _useAutocomplete<T, Multiple, DisableClearable, FreeSolo>({
    // defaults
    openOnFocus: true,

    multiple,
    disableClearable,
    freeSolo,
    ...props,
    // null at least hides the uncontrolled -> controlled warning message
    // @ts-expect-error ignore
    value: props.value ?? null,
  });
  const {
    getInputProps: _getInputProps,
    getListboxProps,
    getOptionProps: _getOptionProps,
    groupedOptions: _groupedOptions,
    dirty,
    inputValue,
  } = result;

  const getOptionProps = useCallback(
    (input: { option: T; index: number }) => {
      const result = _getOptionProps(input);
      const key = getOptionKey(input.option);
      return {
        ...result,
        key,
      };
    },
    [_getOptionProps, getOptionKey]
  );

  // TODO Disabled for multiple because clear resets ALL values
  const hasClearIcon =
    !multiple && !disableClearable && !disabled && dirty && inputValue.length > 0;

  useEffect(() => {
    if (groupBy) {
      if (freeSolo) {
        console.warn("freeSolo is not implemented for groupBy yet");
      }

      if (freeSolo) {
        // You'll probably need a variable sized list per their example:
        // https://mui.com/components/autocomplete/#virtualization
        console.warn("Windowing is not currently supported with grouped options");
      }
    }
  }, [freeSolo, groupBy]);

  const freeSoloOption = useMemo(() => {
    const result = inputValue ? getFreeSoloOption?.(inputValue, _groupedOptions as T[]) : undefined;
    // Don't show "add" option if there is an exact match
    if (
      result &&
      !_groupedOptions.some(
        (o) =>
          getOptionLabel &&
          getOptionLabel(o as T).toLowerCase() === getOptionLabel(result).toLowerCase()
      )
    ) {
      return result;
    }

    return undefined;
  }, [inputValue, getFreeSoloOption, _groupedOptions, getOptionLabel]);

  const groupedOptions = useMemo(() => {
    if (groupBy) {
      return _groupedOptions;
    }

    // TODO Need to revisit for grouped options
    const result = _groupedOptions as T[];
    if (freeSoloOption) {
      result.unshift(freeSoloOption);
    }

    if (!freeSoloOption && emptyOption) {
      result.unshift(emptyOption);
    }

    return result;
  }, [_groupedOptions, emptyOption, freeSoloOption, groupBy]);

  const renderCheckbox = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>) => {
      if (!multiple) {
        return null;
      }

      const ariaSelected = props["aria-selected"];
      return <Checkbox as="span" checked={ariaSelected === true || ariaSelected === "true"} />;
    },
    [multiple]
  );

  const getLabel = useCallback(
    (option: T) => {
      if (typeof option === "string") {
        return option;
      }

      if (props.renderOption) {
        return props.renderOption(option);
      }

      const label = getOptionLabel?.(option) || "-";

      const modifiers = getOptionModifiers?.(option) || [];
      if (modifiers.includes("indent") && !multiple) {
        return (
          <Stack gap="1" direction="horizontal">
            <Box as="span" css={{ color: "$colors$gray600 !important", fontSize: "9px" }}>
              ⎿
            </Box>{" "}
            {label}
          </Stack>
        );
      }

      return label;
    },
    [getOptionLabel, getOptionModifiers, multiple]
  );

  const renderedListbox = useMemo(() => {
    const options = groupedOptions as T[];
    const getLabelWithFreeSolo = (option: T) => {
      if (option === freeSoloOption) {
        return (
          <span>
            + {addText} <Text color="gray500">{`"${getLabel(option)}"`}</Text>
          </span>
        );
      }
      return getLabel(option);
    };

    // TODO use VariableSizeList to handle groupBy
    if (windowed && !groupBy) {
      return (
        <FixedSizeList
          height={Math.min(300, options.length * 30 + LIST_PADDING * 2)}
          itemCount={options.length}
          itemSize={30}
          width={200}
          outerElementType={WindowedOuterElement}
          innerElementType={WindowedListbox}
          overscanCount={5}
        >
          {({ index, style }) => {
            const option = options[index];
            const isFreeSolo = option === freeSoloOption;
            const optionProps = getOptionProps({ option, index });
            return (
              <ListItem
                style={{
                  ...style,
                  top: parseFloat(style.top?.toString() || "0") + LIST_PADDING,
                }}
                showActiveBar={!multiple}
                {...optionProps}
              >
                {!isFreeSolo && renderCheckbox(optionProps)}
                <ListItemLabel>{getLabelWithFreeSolo(option)}</ListItemLabel>
              </ListItem>
            );
          }}
        </FixedSizeList>
      );
    }

    let content: React.ReactNode;
    if (groupBy) {
      const groups = groupedOptions as AutocompleteGroupedOption<T>[];
      let index = -1;
      content = groups.map(({ group, options }, groupIndex) => {
        return (
          <React.Fragment key={groupIndex}>
            <GroupList dark={inDialog}>
              <GroupHeader>{group}</GroupHeader>
              {options.map((option) => {
                index += 1;
                const optionProps = getOptionProps({ option, index });

                return (
                  <ListItem showActiveBar={!multiple} {...optionProps}>
                    {renderCheckbox(optionProps)}
                    <ListItemLabel>{getLabel(option)}</ListItemLabel>
                  </ListItem>
                );
              })}
            </GroupList>
          </React.Fragment>
        );
      });
    } else {
      content = options.map((option, index) => {
        const isFreeSolo = option === freeSoloOption;
        const optionProps = getOptionProps({ option, index });
        const modifiers = getOptionModifiers?.(option) || [];
        const isIndented = modifiers.includes("indent") && multiple;

        return (
          <ListItem
            css={isIndented ? { paddingLeft: "34px" } : undefined}
            showActiveBar={!multiple}
            {...optionProps}
          >
            {/* fragment prevents weird duplicate key warning */}
            <>
              {!isFreeSolo && renderCheckbox(optionProps)}
              <ListItemLabel>{getLabelWithFreeSolo(option)}</ListItemLabel>
            </>
          </ListItem>
        );
      });
    }

    return <Listbox {...getListboxProps()}>{content}</Listbox>;
  }, [
    groupedOptions,
    windowed,
    groupBy,
    getListboxProps,
    freeSoloOption,
    getLabel,
    addText,
    getOptionProps,
    renderCheckbox,
    inDialog,
    getOptionModifiers,
    multiple,
  ]);

  const getInputProps = useCallback(() => {
    return {
      placeholder,
      onBlur,
      ..._getInputProps(),
    };
  }, [_getInputProps, onBlur, placeholder]);

  // @ts-ignore ignore
  const inputRef = useMemo(() => getInputProps().ref, [getInputProps]);

  return {
    hasClearIcon,
    inputRef,
    renderedListbox,
    ...result,
    getOptionProps,
    popupOpen: !disabled && result.popupOpen,
  };
}
