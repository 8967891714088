import { colors, shadows, styled } from "@puzzle/theme";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";

// Use this when you don't want scrollbars to affect positioning, or you need more customization.
// https://www.radix-ui.com/docs/primitives/components/scroll-area

const ScrollAreaContainer = styled(ScrollAreaPrimitive.Root, {
  overflow: "hidden",
});

const Viewport = styled(ScrollAreaPrimitive.Viewport, {
  width: "100%",
  height: "100%",
});

const Scrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
  display: "flex",
  // ensures no selection
  userSelect: "none",
  // disable browser handling of all panning and zooming gestures on touch devices
  touchAction: "none",
  transition: "background 160ms ease-out",
  // "&:hover": { background: blackA.blackA8 },
  '&[data-orientation="vertical"]': { width: "$space$1" },
  '&[data-orientation="horizontal"]': {
    flexDirection: "column",
    height: "$space$1",
  },
});

const Thumb = styled(ScrollAreaPrimitive.Thumb, {
  flex: 1,
  backgroundColor: "$gray500",
  borderRadius: 20,

  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    minWidth: 20,
    minHeight: 20,
  },

  variants: {
    variant: {
      shadowed: {
        backgroundColor: "$mauve600",
        border: `0.5px solid ${colors.white200}`,
        boxShadow: shadows.black20ToBlack10BottomBlurLargeComposed,
      },
    },
  },
});

const Corner = styled(ScrollAreaPrimitive.Corner);

export const ScrollArea = Object.assign(ScrollAreaContainer, {
  Corner,
  Scrollbar,
  Thumb,
  Viewport,
});
