import keyBy from "lodash/keyBy";
import orderBy from "lodash/orderBy";
import omit from "lodash/omit";
import last from "lodash/last";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useLocalStorage } from "react-use";
import { ApolloError, FetchResult } from "@apollo/client";
import { I18nProvider } from "@react-aria/i18n";

import { parseCalendarMonth } from "@puzzle/utils";
import {
  setIsDecimal,
  setCompanyCount,
  setUserRole,
  setActiveCompany,
} from "lib/instrumentation/dataDogRUM";

import {
  CompanyIngestStatus,
  CreateCompanyFromPrefilledDataInput,
  CreateCompanyInput,
  MembershipRole,
  OnboardingStage,
  UpdateCompanyInput,
  useIntegrationConnectionsForCompanyQuery,
  Company,
  IntegrationConnectionWithAccountStatsFragment,
  CoaType,
} from "graphql/types";
import useSelf from "components/users/useSelf";
import {
  ActiveCompanyFragment,
  CreateCompanyFromPrefilledDataMutation,
  CreateCompanyMutation,
  UpdateCompanyMutation,
  useCreateCompanyFromPrefilledDataMutation,
  useCreateCompanyMutation,
  usePricingPlanFeaturesQuery,
  useUpdateCompanyMutation,
  useAllCompaniesLiteQuery,
  useSingleCompanyQuery,
  InactiveCompanyFragment,
  AllCompaniesLiteDocument,
  AllCompaniesLiteQuery,
} from "./graphql.generated";
import { SelfMembershipFragment } from "components/users/graphql.generated";
import Analytics from "lib/analytics/analytics";
import { CalendarDate, DateValue, endOfMonth, getLocalTimeZone } from "@internationalized/date";
import { useQueryState } from "next-usequerystate";
import { useKickCompanyIngestionMutation } from "components/partners/graphql.generated";
import { PricingFeatures } from "./pricingFeatures";
import { mergeDeep } from "@apollo/client/utilities";
import { resetTraceCompanyId } from "lib/instrumentation/otelInstrumentation";
import { isEditorRole } from "lib/roles";
import { useRouter } from "next/router";
import { getClosestStaticRoute, Route } from "lib/routes";
import { useAppRouter } from "lib/useAppRouter";

import { ACTIVE_COMPANY } from "constants/localStorageKeys";
export { PricingFeatures }; // re-export for convenience

type UpdateCompany = (
  input: Omit<UpdateCompanyInput, "id" | "referringPartner">
) => Promise<FetchResult<UpdateCompanyMutation>>;
type CreateOrUpdateCompany = (
  input: CreateCompanyInput,
  onCompleted?: (companyId: string) => void
) => Promise<FetchResult<CreateCompanyMutation | UpdateCompanyMutation>>;
type CreatePrefilledCompany = (
  input: CreateCompanyFromPrefilledDataInput,
  onCompleted?: (companyId: string) => void
) => Promise<FetchResult<CreateCompanyFromPrefilledDataMutation>> | undefined;

export type PlanPriceInformation = {
  interval: string;
  price: number;
};

export type BaseUseActiveCompanyResult = {
  timeZone: string;
  /** @deprecated Use company.id */
  companyId?: string;
  setActiveCompanyId: (id: string) => void;
  loading: boolean;
  completedOnboarding: boolean;
  creatingCompany: boolean;
  updatingCompany: boolean;
  companies: InactiveCompanyFragment[];
  createCompany: CreateOrUpdateCompany;
  createCompanyFromPrefilledData: CreatePrefilledCompany;
  updateCompany: UpdateCompany;
  refetchUserCompanies: () => void;
  lockedPeriodDate?: CalendarDate;
  isWithinLockedPeriod: (date: DateValue) => boolean;
  initialIngestCompleted: boolean;
  refetchActiveCompany: () => void;
  activeCompanyError: ApolloError | undefined;
  alignActiveCompanyWithObjectCompany: (id?: string) => void;
};

export type MaybeLoadedUseActiveCompanyResult = BaseUseActiveCompanyResult & {
  company?: ActiveCompanyFragment;
  memberships?: SelfMembershipFragment[] | null;
  membership?: SelfMembershipFragment | null;
  membershipRole?: MembershipRole;
  isEditor?: boolean;
  pricePlanFeatureEnabled?: Map<PricingFeatures, boolean>;
};
export type LoadedUseActiveCompanyResult = BaseUseActiveCompanyResult & {
  company: ActiveCompanyFragment;
  memberships: SelfMembershipFragment[];
  membership: SelfMembershipFragment;
  membershipRole: MembershipRole;
  integrationConnections: IntegrationConnectionWithAccountStatsFragment[];
  integrationConnectionsLoading: boolean;
  isEditor: boolean;
  refetchIntegrationConnections: () => void;
  startPollingIntegrationConnections: (pollInterval: number) => void;
  stopPollingIntegrationConnections: () => void;
  pricingPlanFeaturesLoading: boolean;
  pricePlanFeatureEnabled: Map<PricingFeatures, boolean>;
  expenseExceededDate?: CalendarDate;
};

const ActiveCompanyContext = React.createContext<BaseUseActiveCompanyResult | null>(null);

export type ActiveCompanyProviderProps = {
  children: React.ReactNode;
};

const useActiveCompanyContextValue = (): MaybeLoadedUseActiveCompanyResult => {
  const { self } = useSelf();
  const [initialCompanyId, setInitialCompanyId] = useQueryState("companyId");
  const [createCompanyMutation, { loading: creatingCompany }] = useCreateCompanyMutation();
  const [createPrefilledCompanyMutation, { loading: creatingPrefilledCompany }] =
    useCreateCompanyFromPrefilledDataMutation();
  const [updateCompanyMutation, { loading: updatingCompany }] = useUpdateCompanyMutation();
  const [kickIngestion] = useKickCompanyIngestionMutation();
  const router = useRouter();
  const { isFirmRoute } = useAppRouter();

  // We could store this as local Apollo state with the @client decorator.
  // Probably a similar amount of code.
  // https://www.apollographql.com/blog/apollo-client/caching/local-state-management-with-reactive-variables/
  const [activeCompanyId = "", setActiveCompanyIdInner] = useLocalStorage<string>(
    ACTIVE_COMPANY,
    initialCompanyId ?? ""
  );

  const setActiveCompanyId = useCallback(
    (id: string) => {
      setActiveCompanyIdInner(id);
      resetTraceCompanyId();
      // if we are on a firm route, setting the active company should bring
      // us back to home, away from the admin portal
      if (isFirmRoute(router.asPath)) {
        router.push(Route.home);
      }
    },
    [setActiveCompanyIdInner, router, isFirmRoute]
  );

  const { data: allCompanies, refetch: refetchUserCompanies } = useAllCompaniesLiteQuery();

  const companies = useMemo(() => allCompanies?.companies ?? [], [allCompanies?.companies]);

  const isValidActiveCompany = useMemo(() => {
    if (!activeCompanyId) return false;
    return companies.some((c) => c.id === activeCompanyId);
  }, [companies, activeCompanyId]);

  const alignActiveCompanyWithObjectCompany = useCallback(
    (objectCompanyId?: string) => {
      if (activeCompanyId === objectCompanyId) {
        // aligned: do nothing
        return;
      }
      if (objectCompanyId && companies.some((c) => c.id === objectCompanyId)) {
        // not aligned, and the user has access to the company
        setActiveCompanyId(objectCompanyId);
      } else {
        // the user doesn't have access to the company
        // and/or the object wasn't found when querying
        // should both be true together
        router.replace(getClosestStaticRoute(router.pathname));
      }
    },
    [activeCompanyId, setActiveCompanyId, companies, router]
  );

  // no active company in local storage, fallback to last company added
  if (!isValidActiveCompany && allCompanies?.companies && allCompanies.companies.length > 0) {
    const sortedCompanies = orderBy(allCompanies?.companies ?? [], (c) => c.createdAt);
    const lastCompany = sortedCompanies[sortedCompanies.length - 1];
    if (lastCompany.id !== activeCompanyId) {
      setActiveCompanyId(lastCompany.id);
    }
  }

  const {
    data: activeCompany,
    error: activeCompanyError,
    loading,
    startPolling,
    stopPolling,
    refetch: refetchActiveCompany,
  } = useSingleCompanyQuery({
    variables: { companyId: activeCompanyId },
    skip: !isValidActiveCompany,
  });
  const company = activeCompany?.company;
  const memberships = useMemo(
    () =>
      (self?.companyMemberships ?? []).filter(
        (m) => m.status === "Active"
      ) as SelfMembershipFragment[],
    [self?.companyMemberships]
  );
  const membershipsByCompanyId = useMemo(() => keyBy(memberships, "companyId"), [memberships]);
  const membership = company?.id ? membershipsByCompanyId[company?.id] : null;

  useEffect(() => {
    if (company) {
      // This should only run each time the company changes, so it should be safe
      // to count how many times the user has logged in or open the page.
      Analytics.userViewedCompany();
      // Set company attribute `hasBookkeeper`
      Analytics.setHasBookkeeper(company as Company, memberships);
    }
  }, [company, memberships]);

  // Set global context company information properties for DataDog RUM
  useEffect(() => {
    if (company && membership && companies) {
      setActiveCompany({ id: company.id, name: company.name });
      setIsDecimal(company.coaType === CoaType.Decimal);
      setCompanyCount(companies.length);
      setUserRole(membership?.role);
    }
  }, [company, companies, membership]);

  // https://linear.app/puzzlefin/issue/GRO-302/fe-to-kick-ingestion-when-stuck
  const lastKickedCompanyId = useRef<string | null>(null);
  useEffect(() => {
    if (
      company?.id &&
      company.onboardingStage === OnboardingStage.Completed &&
      company.initialIngestStatus === CompanyIngestStatus.New &&
      lastKickedCompanyId.current !== company.id
    ) {
      lastKickedCompanyId.current = company.id;
      kickIngestion({ variables: { input: { id: company.id } } });
    }
  }, [company?.id, company?.initialIngestStatus, company?.onboardingStage, kickIngestion]);

  useEffect(() => {
    if (!company) {
      return;
    }

    // Start polling for ingest status once onboarding is complete
    if (
      company.initialIngestStatus !== CompanyIngestStatus.UpToDate &&
      company.onboardingStage === OnboardingStage.Completed
    ) {
      startPolling(2000);
    } else {
      stopPolling();
    }

    return stopPolling;
  }, [company, stopPolling, startPolling]);

  useEffect(() => {
    if (initialCompanyId) {
      setActiveCompanyId(initialCompanyId);
      setInitialCompanyId(null);
    }
  }, [initialCompanyId, setInitialCompanyId, setActiveCompanyId]);

  const timeZone = useMemo(() => company?.timeZone || getLocalTimeZone(), [company?.timeZone]);

  useEffect(() => {
    if (company?.id && company.id !== activeCompanyId) {
      setActiveCompanyId(company.id);
    }
  }, [activeCompanyId, company?.id, setActiveCompanyId]);

  useEffect(() => {
    if (!activeCompanyId && companies.length > 0) {
      setActiveCompanyId(last(companies)!.id);
    }
  }, [activeCompanyId, companies, setActiveCompanyId]);

  const updateCompany = useCallback(
    async (input: Omit<UpdateCompanyInput, "id" | "referringPartner">) => {
      if (!company) {
        throw new Error("No company exists");
      }

      const result = await updateCompanyMutation({
        variables: {
          input: {
            id: company.id,
            //set startIngestionDate to null if user switches hasHistorical data after setting a date
            startIngestionDate:
              input.hasHistoricalData === false
                ? null
                : input.startIngestionDate
                  ? input.startIngestionDate
                  : company.startIngestionDate,
            ...input,
          },
        },

        optimisticResponse: {
          updateCompany: {
            company: {
              ...company,
              ...input,
              mfaRequired: input.mfaRequired ?? company.mfaRequired,
              name: input.name || company.name,
              referralCode: input.referralCode || company.referralCode,
              attributes: company.attributes,
              type: company.type,
            },
          },
        },

        onCompleted(data) {
          const { company } = data.updateCompany;
          Analytics.companySettingsUpdated({
            name: company.name,
            revenueModel: company.revenueModel!,
            timeZone: company.timeZone!,
          });
          if (input.userProposedStartIngestionDate) {
            Analytics.userProposedStartIngestionDate({
              date: input.userProposedStartIngestionDate,
            });
          }
        },
      });

      return result;
    },
    [company, updateCompanyMutation]
  );

  const createCompany = useCallback(
    (input: CreateCompanyInput, onCompleted?: (companyId: string) => void) => {
      if (company) {
        return updateCompany(
          omit(input, ["coaType", "orgType", "referringPartner", "partnerConnectionRequestId"])
        );
      }

      return createCompanyMutation({
        variables: { input },
        update(cache, { data }) {
          // Need to update cache to ensure that useSingleCompanyQuery will fetch new company
          if (!data) return;
          setActiveCompanyId(data.createCompany.company.id);

          const companiesQuery = cache.readQuery<AllCompaniesLiteQuery>({
            query: AllCompaniesLiteDocument,
          });

          if (!companiesQuery) return;

          let { companies } = companiesQuery;
          const { name, id, __typename, createdAt } = data.createCompany.company;
          companies = [...companies, { name, id, __typename, createdAt }];

          cache.writeQuery<AllCompaniesLiteQuery>({
            query: AllCompaniesLiteDocument,
            data: mergeDeep(companiesQuery, {
              companies: [...companiesQuery.companies, data.createCompany.company],
            }),
          });
        },
        onCompleted: ({ createCompany }) => {
          onCompleted?.(createCompany.company.id);
        },
      });
    },
    [company, createCompanyMutation, updateCompany, setActiveCompanyId]
  );

  const createCompanyFromPrefilledData = useCallback(
    (input: CreateCompanyFromPrefilledDataInput, onCompleted?: (companyId: string) => void) => {
      if (company) {
        return;
      }

      return createPrefilledCompanyMutation({
        variables: { input },
        update(cache, { data }) {
          if (!data) return;
          refetchUserCompanies();
          setActiveCompanyId(data.createCompanyFromPrefilledData.company.id);
        },
        onCompleted: ({ createCompanyFromPrefilledData }) => {
          onCompleted?.(createCompanyFromPrefilledData.company.id);
        },
      });
    },
    [company, createPrefilledCompanyMutation, refetchUserCompanies, setActiveCompanyId]
  );

  const lockedPeriodDate = useMemo(() => {
    return company?.lockedPeriod?.period
      ? endOfMonth(parseCalendarMonth(company.lockedPeriod.period))
      : undefined;
  }, [company?.lockedPeriod]);

  const isWithinLockedPeriod = useCallback(
    (date: DateValue) => Boolean(lockedPeriodDate && date.compare(lockedPeriodDate) <= 0),
    [lockedPeriodDate]
  );

  const completedOnboarding = useMemo(() => {
    if (!company || !self) {
      return false;
    }
    return company.onboardingStage === OnboardingStage.Completed;
  }, [company, self]);

  const {
    data: integrationConnectionsData,
    loading: integrationConnectionsLoading,
    refetch: refetchIntegrationConnections,
    startPolling: startPollingIntegrationConnections,
    stopPolling: stopPollingIntegrationConnections,
  } = useIntegrationConnectionsForCompanyQuery({
    skip: !company,
    variables: { companyId: company?.id ?? "" },
    fetchPolicy: "cache-and-network",
  });

  const integrationConnections = useMemo(
    () => integrationConnectionsData?.company?.integrationConnections || [],
    [integrationConnectionsData]
  );

  const {
    data: pricingPlanFeaturesData,
    loading: pricingPlanFeaturesLoading,
    refetch: refetchPricingPlanFeatures,
  } = usePricingPlanFeaturesQuery({
    skip: !company,
    variables: { companyId: company?.id ?? "" },
    fetchPolicy: "cache-and-network",
  });

  const pricePlanFeatureEnabled = useMemo(() => {
    const newMap = new Map<PricingFeatures, boolean>();
    if (pricingPlanFeaturesData?.pricingPlanFeatures?.featureNames) {
      pricingPlanFeaturesData.pricingPlanFeatures?.featureNames.forEach((name) => {
        newMap.set(name as PricingFeatures, true);
      });
    }
    return newMap;
  }, [pricingPlanFeaturesData]);

  const isEditor = membership?.role && isEditorRole(membership?.role);
  return useMemo(
    () => ({
      companies,
      company: company ?? undefined,
      refetchUserCompanies,
      companyId: company?.id,
      createCompany,
      creatingCompany: creatingCompany || updatingCompany || creatingPrefilledCompany,
      createCompanyFromPrefilledData,
      loading,
      memberships,
      membershipRole: membership?.role,
      membership,
      setActiveCompanyId,
      updateCompany,
      updatingCompany,
      timeZone,
      lockedPeriodDate,
      isWithinLockedPeriod,
      completedOnboarding,
      initialIngestCompleted: company?.initialIngestStatus === CompanyIngestStatus.UpToDate,
      integrationConnections,
      isEditor,
      refetchIntegrationConnections,
      startPollingIntegrationConnections,
      stopPollingIntegrationConnections,
      integrationConnectionsLoading,
      pricePlanFeatureEnabled,
      pricingPlanFeaturesLoading,
      refetchPricingPlanFeatures,
      expenseExceededDate: pricingPlanFeaturesData?.pricingPlanFeatures?.expenseExceededDate,
      refetchActiveCompany,
      activeCompanyError,
      alignActiveCompanyWithObjectCompany,
    }),
    [
      companies,
      company,
      refetchUserCompanies,
      createCompany,
      creatingCompany,
      updatingCompany,
      creatingPrefilledCompany,
      createCompanyFromPrefilledData,
      loading,
      memberships,
      membership,
      setActiveCompanyId,
      updateCompany,
      timeZone,
      lockedPeriodDate,
      isWithinLockedPeriod,
      completedOnboarding,
      integrationConnections,
      integrationConnectionsLoading,
      isEditor,
      refetchIntegrationConnections,
      startPollingIntegrationConnections,
      stopPollingIntegrationConnections,
      pricingPlanFeaturesData,
      pricePlanFeatureEnabled,
      pricingPlanFeaturesLoading,
      refetchPricingPlanFeatures,
      refetchActiveCompany,
      activeCompanyError,
      alignActiveCompanyWithObjectCompany,
    ]
  );
};

export const ActiveCompanyProvider = ({ children }: ActiveCompanyProviderProps) => {
  const value = useActiveCompanyContextValue();

  return (
    <I18nProvider
      // Hardcoded until we're asked to support more localization
      locale="en-US"
    >
      <ActiveCompanyContext.Provider value={value}>{children}</ActiveCompanyContext.Provider>
    </I18nProvider>
  );
};

export function useActiveCompany<Loaded extends boolean = false>() {
  const context = React.useContext(ActiveCompanyContext);
  if (context === null) {
    throw new Error("ActiveCompanyContext not found");
  }
  return context as Loaded extends true
    ? LoadedUseActiveCompanyResult
    : MaybeLoadedUseActiveCompanyResult;
}
