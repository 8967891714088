import omit from "lodash/omit";
import React, { useMemo } from "react";
import * as ToastPrimitive from "@radix-ui/react-toast";

import { Close } from "@puzzle/icons";
import { keyframes, styled, CSS, shadows, colors } from "@puzzle/theme";

import { StatusIcon, StatusIconState } from "../StatusIcon";
import { IconButton } from "../Button";
import { type ToastStatus, ToastItem } from "./types";
import { ANIMATION_TIMING, VIEWPORT_PADDING, DEFAULT_TOAST_WARNING_MESSAGE } from "./common";

const getStatusIconState = (status: ToastStatus): StatusIconState | null => {
  switch (status) {
    case "success":
      return "success";
    case "warning":
      return "warning";
    case "error":
      return "error";
    case null:
      return null;
  }
};

const hide = keyframes({
  "0%": { opacity: 1 },
  "100%": {
    opacity: 0,
    transform: "translate(-50%, var(--puzzle-toast-vertical-offset)) scale(0.8)",
  },
});

const slideIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const swipeOut = keyframes({
  from: {
    transform: "translate(var(--radix-toast-swipe-end-x), var(--puzzle-toast-vertical-offset))",
  },
  to: {
    transform: `translate(calc(100% + ${VIEWPORT_PADDING}), var(--puzzle-toast-vertical-offset))`,
  },
});

const ToastRoot = styled(ToastPrimitive.Root, {
  position: "absolute",
  bottom: 0,
  right: 0,
  left: "50%",
  transform: "translateX(-50%)",
  width: "max-content",
  maxWidth: "100%",
  display: "flex",
  flexDirection: "row",
  background: "$mauve700",
  padding: "$2",
  border: `1px solid ${colors.mauve700}`,
  boxSizing: "border-box",
  boxShadow: shadows.black10ToBlack10BottomBlurMediumComposed,
  borderRadius: "$1",
  outline: "none",
  userSelect: "none",

  "&:focus": {
    borderColor: "$gray500",
  },

  "@animationEnabled": {
    willChange: "transform",

    // for reasons, I had to make these custom CSS variables
    transform: "translate(-50%, var(--puzzle-toast-vertical-offset, 0))",
    transition: `all ${ANIMATION_TIMING.open}ms cubic-bezier(0.4, 0, 0.2, 1)`,

    '&[data-state="open"]': {
      animation: `${slideIn} ${ANIMATION_TIMING.open}ms cubic-bezier(0.4, 0, 0.2, 1)`,
    },

    '&[data-state="closed"]': {
      animation: `${hide} ${ANIMATION_TIMING.close}ms ease-in forwards`,
    },

    '&[data-swipe="move"]': {
      transform: "translate(var(--radix-toast-swipe-move-x), var(--puzzle-toast-vertical-offset))",
      cursor: "grabbing",
    },

    '&[data-swipe="cancel"]': {
      transform: "translate(-50%, var(--puzzle-toast-vertical-offset))",
      transition: `transform ${ANIMATION_TIMING.cancel}ms ease-out`,
    },

    '&[data-swipe="end"]': {
      animation: `${swipeOut} ${ANIMATION_TIMING.close}ms ease-out forwards`,
    },
  },
});

const ToastContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$1",
  flex: 1,
  marginTop: -1,
});

const ToastTitle = styled(ToastPrimitive.Title, {
  display: "flex",
  textVariant: "$headingS",
  color: "$neutral100",
});

const ToastDescription = styled(ToastPrimitive.Description, {
  textVariant: "$bodyS",
  color: "$gray400",

  a: {
    color: "$gray200",
  },
});

const ToastStatus = styled("div", {
  marginRight: "$1h",
  lineHeight: 0,
  height: "fit-content",
});

const ToastClose = styled(ToastPrimitive.Close, {
  marginLeft: "$1",
  marginTop: 2,
  color: "$gray500",
});

const ToastAction = styled(ToastPrimitive.Action, {
  all: "unset",
  textVariant: "$bodyS",
  padding: 0,
  color: "$gray200",
  cursor: "pointer",
});

type ToastProps = ToastItem & {
  onClose?: () => void;
  css?: CSS;
} & React.ComponentProps<typeof ToastRoot>;

function getFallbackMessage(status: ToastProps["status"]) {
  if (status === "error" || status === "warning") {
    return DEFAULT_TOAST_WARNING_MESSAGE;
  }

  return undefined;
}

export const Toast = React.forwardRef<HTMLLIElement, ToastProps>(
  ({ status = "success", open, onClose, sensitivity, css, id, ...props }, ref) => {
    const title = props.title;
    const message = "message" in props ? props.message : getFallbackMessage(status);

    const action = useMemo(() => {
      if ("actionText" in props) {
        return (
          <ToastAction onClick={props.onAction} altText={props.actionAltText ?? props.actionText}>
            {props.actionText}
          </ToastAction>
        );
      } else if ("action" in props) {
        return (
          <ToastAction asChild altText={props.actionAltText}>
            {props.action}
          </ToastAction>
        );
      }

      return null;
    }, [props]);

    const statusIconState = getStatusIconState(status);

    return (
      <ToastRoot
        css={css}
        ref={ref}
        open={open}
        type={sensitivity}
        onOpenChange={(open) => {
          if (!open && onClose) {
            onClose();
          }
        }}
        data-id={id}
        {...omit(props, ["message"])}
      >
        {statusIconState && (
          <ToastStatus>
            <StatusIcon status={statusIconState} size={19} active />
          </ToastStatus>
        )}
        <ToastContent>
          {title && <ToastTitle>{title}</ToastTitle>}
          {message && <ToastDescription>{message}</ToastDescription>}
          {action}
        </ToastContent>
        {onClose && (
          <ToastClose asChild onClick={onClose}>
            <IconButton size="small">
              <Close color="currentColor" />
            </IconButton>
          </ToastClose>
        )}
      </ToastRoot>
    );
  }
);
