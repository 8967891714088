import { createBreakpoint } from "react-use";

export const breakpoints = {
  xs: 376,
  s: 640,
  m: 768,
  l: 1024,
  xl: 1280,
  xxl: 1536,
  fhd: 1920,
  qhd: 2560,
  uhd: 3840,
};

export const useBreakpoint = createBreakpoint(breakpoints) as () => keyof typeof breakpoints;

export const media = {
  xs: `(min-width: ${breakpoints.xs}px)`,
  s: `(min-width: ${breakpoints.s}px)`,
  m: `(min-width: ${breakpoints.m}px)`,
  l: `(min-width: ${breakpoints.l}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
  xxl: `(min-width: ${breakpoints.xxl}px)`,
  fhd: `(min-width: ${breakpoints.fhd}px)`,
  qhd: `(min-width: ${breakpoints.qhd}px)`,
  uhd: `(min-width: ${breakpoints.uhd}px)`,
  animationEnabled: "(prefers-reduced-motion: no-preference)",
};
