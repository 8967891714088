import { colors } from "@puzzle/theme";

export const Colors = {
  White: colors.neutral100,
  Active: colors.greenalpha,
  BaseGrey: colors.gray300,
  BaseDarkGrey: colors.gray700,
  ActiveGrey: colors.gray400,
  Negative: colors.red500,
  Warning: colors.yellow500,
};
