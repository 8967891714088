// TODO Automate with something like Pathpida
// https://linear.app/puzzlefin/issue/PZ-2292/typesafe-routes-for-nextjs
export enum Route {
  sync = "/api/sync",
  token = "/api/token",
  login = "/api/login",
  authorizePartner = "/api/authorize-partner",
  // This first goes to Auth0, then our own logout handler.
  logout = "/logout",
  me = "/api/me",
  home = "/",
  aiLabs = "/ai-labs",
  emailVerification = "/email-verification",
  emailVerificationSuccess = "/email-verification-success",
  emailNotifications = "/email-notifications",
  connectPlaid = "/oauth/plaid",
  connectFinicity = "/oauth/finicity",
  connectBrex = "/oauth/brex",
  connectGusto = "/oauth/gusto",
  connectRippling = "/oauth/rippling",
  linkRippling = "/link/rippling",
  connectQuickbooks = "/oauth/quickbooks",
  connectStripe = "/oauth/stripe",
  integrations = "/integrations",
  connectionError = "/oauth/error",
  createCompany = "/company/create",
  intro = "/intro",
  invoices = "/accounting/invoices",
  newInvoice = "/accounting/invoices/new",
  error = "/error",
  accountDetails = "/account",
  burn = "/burn",
  landing = "/landing",
  checklist = "/accuracy-review",
  companyPrivateRoute = "/company",
  reports = "/reports",
  printableReport = "/print",
  report = "/reports/:id",
  revenue = "/revenue",
  accrualRevenue = "/revenue/accrual",
  mrrRevenue = "/revenue/mrr-arr",
  mrrRevenueByCustomer = "/revenue/mrr-arr-customers",
  expenses = "/expenses",
  people = "/people",
  ltse = "/ltse",
  angelListConnect = "/connect/angellist",
  connectSuccess = "/connect/success",
  angellistCategorize = "/connect/angellist/categorize",
  angellistManage = "/connect/angellist/manage",
  genericConnect = "/connect/generic",
  acmeConnect = "/connect/acme",
  introConnect = "/connect/intro",
  genericCategorize = "/connect/generic/categorize",
  connectPartnerDataSource = "/connect/partner-data-source",
  transactions = "/transactions",
  rules = "/transactions/rules",
  rulesSettings = "/settings/rules",
  settings = "/settings",
  companySettings = "/settings/company",
  automationSettings = "/settings/automation",
  notificationSettings = "/settings/notifications",
  auditLog = "/settings/audit-log",
  billing = "/settings/billing",
  historicalBooks = "/settings/historical-books",
  historicalBooksError = "/settings/historical-books/error/:fileId",
  classesSettings = "/settings/classes",
  usersSettings = "/settings/users",
  dataSettings = "/settings/data",
  otherSettings = "/settings/other",
  policySettings = "/settings/policies",
  chartOfAccountsSettings = "/settings/coa",
  lockedPeriodSettings = "/settings/locked-period",
  accounting = "/accounting",
  chartOfAccounts = "/accounting/coa",
  generalLedger = "/accounting/ledger",
  manualJournals = "/accounting/journals",
  newManualJournal = "/accounting/journals/new",
  groupedMJE = "/accounting/manual-journals",
  newGroupedMJE = "/accounting/manual-journals/new",
  editGroupedMJE = "/accounting/manual-journals/:id/edit",
  lockedPeriod = "/accounting/locked-period",
  payroll = "/payroll",
  bills = "/accounting/bills",
  newBill = "/accounting/bills/new",
  fixedAssets = "/accounting/fixed-assets",
  fixedAssetsTransactions = "/accounting/fixed-assets/transactions",
  prepaidExpenses = "/accounting/prepaid-expenses",
  newPayment = "/accounting/payments/new",
  newInvoicePayment = "/accounting/payments/new?paymentType=Invoice",
  newBillPayment = "/accounting/payments/new?paymentType=Bill",
  prepaidExpensesTransactions = "/accounting/prepaid-expenses/transactions",
  spending = "/spending",
  vendorList = "/spending/vendor-list",
  askAccountant = "/ask-an-accountant",
  inbox = "/inbox",
  partnerOnboarding = "partner-onboarding/:partner",
  ledgerReconciliations = "/accounting/ledger-reconciliations",
  ledgerReconciliationsByAccount = "/accounting/ledger-reconciliations/account/:accountId",
  newLedgerReconciliation = "/accounting/ledger-reconciliations/account/:accountId/new",
  ledgerReconciliationViewer = "/accounting/ledger-reconciliations/:reconciliationId",
  vendors = "/vendors",
  vendors1099 = "/vendors/1099",
  customers = "/manage-customers",
  apAging = "/accounting/ap-aging",
  stripe = "/transactions/stripe",
  stripeDetail = "/transactions/stripe/:bucketId",
  transactionsOnboarding = "/transactions/onboarding",
  transactionsShortcut = "/transactions/shortcut",
  icons = "/icons",
  arAging = "/accounting/ar-aging",
  accountingConfigurations = "/accounting/policies",
  revenueRecognition = "/accounting/revenue-recognition",
  products = "/products",
  noAccess = "/no-access",
  firmBookkeepers = "/firm/bookkeepers",
  firmGroups = "/firm/groups",
}
const RouteMap = new Set(Object.values(Route));

export enum CompanyRoute {
  auth = "auth",
}

export enum ErrorPageCode {
  Whitelist = "whitelist",
  VerifyEmail = "verify_email",

  CodeInvalid = "code_invalid",
  CodeExpired = "code_expired",
  CodeAlreadyActivated = "code_already_activated",
  CodeError = "code_error",

  AuthError = "auth_error",
}

export const isDynamicRoute = (path: string) => {
  return /\[(.*?)\]/.test(path);
};

export const getClosestStaticRoute = (path: string) => {
  const segments = path.split("/");
  const redirectPathParts: string[] = [];

  for (const segment of segments) {
    if (isDynamicRoute(segment)) break;
    redirectPathParts.push(segment);
  }

  let redirectPath = redirectPathParts.join("/");
  for (let i = 0; i < redirectPathParts.length; i++) {
    if (RouteMap.has(redirectPath as Route)) {
      break;
    } else {
      const pathMinusNSegments = redirectPathParts
        .slice(0, redirectPathParts.length - 1 - i)
        .join("/");
      redirectPath = pathMinusNSegments;
    }
  }

  return redirectPath || "/";
};

const ROUTE_SETTINGS: Partial<
  Record<Route, { title: string; entityName: string; entityNamePlural: string }>
> = {
  [Route.inbox]: {
    title: "Inbox",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.checklist]: {
    title: "Accuracy review",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.reports]: {
    title: "Reports",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.accounting]: {
    title: "Accounting",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.generalLedger]: {
    title: "General ledger",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.ledgerReconciliations]: {
    title: "Reconciliations",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.manualJournals]: {
    title: "Manual journal entries",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.groupedMJE]: {
    title: "Manual journal entries",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.fixedAssets]: {
    title: "Fixed assets",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.transactions]: {
    title: "Transactions",
    entityName: "Transaction",
    entityNamePlural: "Transactions",
  },
  [Route.spending]: {
    title: "Spending",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.revenue]: {
    title: "Revenue",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.invoices]: {
    title: "Invoices",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.customers]: {
    title: "Customers",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.arAging]: {
    title: "AR aging",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.people]: {
    title: "People",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.integrations]: {
    title: "Integrations",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.companySettings]: {
    title: "Settings",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.rulesSettings]: {
    title: "Rules",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.automationSettings]: {
    title: "Automation",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.policySettings]: {
    title: "Policy settings",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.chartOfAccountsSettings]: {
    title: "Chart of accounts",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.lockedPeriodSettings]: {
    title: "Locked period",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.notificationSettings]: {
    title: "Notification settings",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.otherSettings]: {
    title: "Other settings",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.classesSettings]: {
    title: "Classifications",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.bills]: {
    title: "Bills",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.vendors]: {
    title: "Vendors",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.prepaidExpenses]: {
    title: "Prepaid expenses",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.apAging]: {
    title: "AP aging",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.auditLog]: {
    title: "Audit log",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.askAccountant]: {
    title: "Ask an accountant",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.billing]: {
    title: "Billing",
    entityName: "",
    entityNamePlural: "",
  },
  [Route.historicalBooks]: {
    title: "Historical books",
    entityName: "",
    entityNamePlural: "",
  },
};

export const getRouteInfo = (
  currentRoute: Route
): { title: string; entityName: string; entityNamePlural: string } | undefined => {
  const firstPartOfPathname = `/${currentRoute.split("/")[1]}` as Route;
  return ROUTE_SETTINGS[firstPartOfPathname];
};

export const ACCOUNTING_ROUTES = [
  {
    path: Route.generalLedger,
    displayText: "General ledger",
    testIdKey: "GENERAL_LEDGER",
  },
  {
    path: Route.chartOfAccounts,
    displayText: "Chart of accounts",
    testIdKey: "CHART_OF_ACCOUNTS",
  },
  {
    path: Route.ledgerReconciliations,
    displayText: "Reconciliations",
    testIdKey: "RECONCILIATIONS",
  },
  {
    path: Route.manualJournals,
    displayText: "Manual journal entries",
    testIdKey: "MANUAL_JOURNALS",
  },
  {
    path: Route.fixedAssets,
    displayText: "Fixed assets",
    testIdKey: "FIXED_ASSETS",
  },
  {
    path: Route.automationSettings,
    displayText: "Settings",
    testIdKey: "AUTOMATION_SETTINGS",
  },
] as const;
