import React, { useState } from "react";
import { Presence } from "@radix-ui/react-presence";
import { VariantProps } from "@stitches/react";
import { usePrevious } from "react-use";

import { Ellipsis } from "@puzzle/icons";
import { colors, shadows, keyframes, styled } from "@puzzle/theme";
import { zIndex } from "@puzzle/utils";

import { Button } from "./Button";
import { Menu } from "./Menu/Menu";

const scaleFadeIn = keyframes({
  from: { transform: "scale(0.95)", opacity: 0 },
  to: { transform: "scale(1)", opacity: 1 },
});

const scaleFadeOut = keyframes({
  from: { transform: "scale(1)", opacity: 1 },
  to: { transform: "scale(0.95)", opacity: 0 },
});

const durations = {
  open: 200,
  close: 150,
};

const ContainerRoot = styled("div", {
  display: "flex",
  position: "fixed",
  bottom: "$10",
  left: 0,
  width: "100vw",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "$3",
  zIndex: zIndex("floatingActionBar"),
  pointerEvents: "none",

  '&[data-state="open"]': {
    animation: `${scaleFadeIn} ${durations.open}ms cubic-bezier(0.4, 0, 0.2, 1)`,
  },

  '&[data-state="closed"]': {
    animation: `${scaleFadeOut} ${durations.close}ms cubic-bezier(0.4, 0, 0.2, 1)`,
  },
});
const Root = ({
  children,
  present = true,
  ...props
}: React.PropsWithChildren<Partial<Omit<React.ComponentProps<typeof Presence>, "children">>>) => {
  // Don't show the initial state while animating out
  const previousChildren = usePrevious(children);
  return (
    <Presence present={present} {...props}>
      <ContainerRoot data-state={present ? "open" : "closed"}>
        {present ? children : previousChildren}
      </ContainerRoot>
    </Presence>
  );
};

const BaseActionBar = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$2",
  backgroundColor: "$mauve700",
  border: `1px solid ${colors.black50}`,
  padding: "$1 $2",
  width: "fit-content",
  borderRadius: "$1",
  boxShadow: shadows.black10ToBlack20BottomBlurMediumHugeComposed,
  pointerEvents: "initial",
});

const MultiActionBarRoot = styled(BaseActionBar, {
  color: "$gray400",
});
const MultiActionBar = ({
  children,
  actions,
  ...props
}: React.PropsWithChildren<VariantProps<typeof MultiActionBarRoot>> & {
  actions: {
    icon?: React.ReactElement;
    label?: string;
    subMenu?: boolean;
    warning?: boolean;
    disabled?: boolean;
    hide?: boolean;
    onClick: React.MouseEventHandler;
    customNode?: React.ReactNode;
  }[];
}) => {
  const [open, onOpenChange] = useState(false);
  return (
    <MultiActionBarRoot {...props} data-testid="@puzzle/ui/FloatingActions/MultiActionBar">
      {children}
      {actions.map(({ icon, label, subMenu, warning, hide, onClick, customNode, ...props }) =>
        !subMenu && !hide ? (
          customNode ? (
            customNode
          ) : (
            <Button
              size="compact"
              variant="secondary"
              prefix={icon}
              key={label}
              onClick={onClick}
              {...props}
            >
              {label}
            </Button>
          )
        ) : (
          !hide && (
            <Menu
              side="top"
              align="end"
              negative={warning ? true : false}
              open={open}
              key={`${label}-menu`}
              onOpenChange={onOpenChange}
              trigger={
                <Button
                  variant="minimal"
                  key={`${label}-button`}
                  css={{
                    width: "32px",
                    height: "32px",
                    justifyContent: "center",
                    lineHeight: 0,
                  }}
                >
                  <Ellipsis color={colors.gray500} />
                </Button>
              }
            >
              <Menu.Item onClick={onClick} key={`${label}-item`}>
                {label}
              </Menu.Item>
            </Menu>
          )
        )
      )}
    </MultiActionBarRoot>
  );
};

const ActionBarRoot = styled(BaseActionBar, {
  color: "$green800",
  cursor: "pointer",
  fontWeight: "$bold",
  outline: "none",
  transition: "all 0.1s ease-in",
  "&:hover, &:focus": {
    color: "$green700",
  },
});
const ActionBar = (
  props: React.PropsWithChildren<VariantProps<typeof ActionBarRoot>> &
    React.HTMLAttributes<HTMLButtonElement>
) => {
  return (
    <ActionBarRoot {...props} as="button" data-testid="@puzzle/ui/FloatingActions/ActionBar" />
  );
};

export const FloatingActions = Object.assign(Root, {
  ActionBar,
  MultiActionBar,
});
