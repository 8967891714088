import React, { useId } from "react";
import { keyframes, styled } from "@stitches/react";
import * as Stitches from "@stitches/react";
import { colors as themeColors } from "@puzzle/theme";

const SpinnerContainer = styled("div", {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const spin = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

const SpinnerSvg = styled("svg", {
  animation: `${spin} 800ms linear 0s infinite`,
});

export type SpinnerProps = {
  colors: {
    trackColor: string;
    thumbColor: string;
  };
  size?: number;
  css?: Stitches.CSS;
};

export const Spinner = ({ colors, size = 16, css }: SpinnerProps) => {
  const maskId = useId();

  return (
    <SpinnerContainer css={css}>
      <SpinnerSvg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          opacity="0.18"
          cx="8"
          cy="8"
          r="7"
          stroke={colors.trackColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask id={maskId} fill={themeColors.white}>
          <path d="M15 8C15.5523 8 16.0064 7.55059 15.9376 7.00263C15.8486 6.29475 15.6652 5.60044 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.3996 0.334793 9.70525 0.151361 8.99737 0.0624143C8.44941 -0.0064372 8 0.447698 8 0.99996C8 1.55222 8.45073 1.99144 8.99534 2.08305C9.4406 2.15795 9.87702 2.28305 10.2961 2.45665C11.0241 2.75818 11.6855 3.20014 12.2427 3.7573C12.7999 4.31446 13.2418 4.97591 13.5434 5.70387C13.7169 6.12298 13.842 6.5594 13.9169 7.00466C14.0086 7.54927 14.4478 8 15 8Z" />
        </mask>
        <path
          d="M15 8C15.5523 8 16.0064 7.55059 15.9376 7.00263C15.8486 6.29475 15.6652 5.60044 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.3996 0.334793 9.70525 0.151361 8.99737 0.0624143C8.44941 -0.0064372 8 0.447698 8 0.99996C8 1.55222 8.45073 1.99144 8.99534 2.08305C9.4406 2.15795 9.87702 2.28305 10.2961 2.45665C11.0241 2.75818 11.6855 3.20014 12.2427 3.7573C12.7999 4.31446 13.2418 4.97591 13.5434 5.70387C13.7169 6.12298 13.842 6.5594 13.9169 7.00466C14.0086 7.54927 14.4478 8 15 8Z"
          stroke={colors.thumbColor}
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          mask={`url(#${maskId})`}
        />
      </SpinnerSvg>
    </SpinnerContainer>
  );
};
