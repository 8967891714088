import Icon, { IconProps } from "./Icon";
import { colors } from "@puzzle/theme";

export default function PaidAddOn({
  viewBox = "0 0 18 18",
  width = 18,
  height = 18,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} viewBox={viewBox} {...iconProps}>
      <rect width="18" height="18" fill={colors.neutral800} rx="9" />
      <path
        fill={colors.greenalpha}
        d="M8.477 14.09V3.183h.7V14.09h-.7Zm2.352-7.635a1.338 1.338 0 0 0-.622-1.006c-.363-.239-.81-.358-1.338-.358-.386 0-.724.062-1.014.187a1.62 1.62 0 0 0-.673.516c-.16.219-.239.467-.239.746 0 .233.056.433.166.6.114.165.259.303.435.414.176.108.36.197.554.268.193.069.37.124.533.167l.886.238c.227.06.48.142.759.247.28.106.55.249.805.43.258.18.472.41.64.691.167.281.25.627.25 1.036 0 .471-.123.897-.37 1.278-.245.38-.602.683-1.074.908-.469.224-1.038.336-1.709.336-.625 0-1.166-.1-1.623-.302a2.588 2.588 0 0 1-1.074-.844 2.407 2.407 0 0 1-.44-1.257h1.092c.028.33.139.602.332.818.196.213.443.372.742.478.3.102.625.153.971.153a2.86 2.86 0 0 0 1.087-.196c.32-.134.575-.318.763-.554.187-.239.28-.517.28-.835 0-.29-.08-.526-.242-.708a1.783 1.783 0 0 0-.64-.443 6.72 6.72 0 0 0-.856-.298l-1.074-.307c-.681-.196-1.221-.476-1.619-.84-.398-.363-.597-.839-.597-1.427 0-.489.133-.915.397-1.279.267-.366.625-.65 1.074-.852a3.626 3.626 0 0 1 1.512-.307c.563 0 1.063.101 1.5.303.438.199.784.472 1.04.818.259.347.395.74.41 1.18h-1.024Z"
      />
    </Icon>
  );
}
