import compact from "lodash/compact";
import isNil from "lodash/isNil";
import keyBy from "lodash/keyBy";
import orderBy from "lodash/orderBy";
import { useCallback, useEffect, useMemo } from "react";
import { useCategoriesQuery } from "./graphql.generated";
import Big from "big.js";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { makeVar } from "@apollo/client";

const fetched = makeVar(false);

// TODO would be nice to guarantee loading the categories before reaching this hook
export function useCategories() {
  const { company } = useActiveCompany();
  const { data, loading, error, startPolling, stopPolling } = useCategoriesQuery({
    skip: !company,
    fetchPolicy: fetched() ? "cache-first" : "cache-and-network",
    variables: !company
      ? undefined
      : {
          input: {
            companyId: company.id,
          },
        },
    onError: (e) => {
      // don't throw the error, since it is used to determine if the COA is ready
      console.warn("Error loading the COA", e);
    },
    onCompleted: () => {
      fetched(true);
    },
  });

  const coaReady = useMemo(
    () => Boolean(company && !error && !loading && data?.categories.length),
    [error, loading, company, data]
  );

  useEffect(() => {
    // only start polling once it's been called once - otherwise the input variables may not be
    // applied
    if (!coaReady) {
      startPolling(1000);
    } else {
      stopPolling();
    }
  }, [coaReady, startPolling, stopPolling]);

  const categories = useMemo(() => {
    return orderBy(
      data?.categories.filter((c) => !c.deprecated && c.ruleType !== "extended_category_match") ??
        [],
      "name"
    );
  }, [data?.categories]);

  // FIXME These should be asserted as partial
  const categoriesByPermaKey = useMemo(
    () => (coaReady ? keyBy(categories, "permaKey") : undefined),
    [categories, coaReady]
  );

  const categoriesById = useMemo(() => keyBy(categories, "id"), [categories]);

  const uncategorizedPermaKeys = useMemo(
    () =>
      !categoriesByPermaKey
        ? undefined
        : compact(
            [
              "no_category",
              "uncategorized_revenue",
              "uncategorized_reimbursements",
              "uncategorized_expenses",
            ].map((x) => categoriesByPermaKey[x]?.permaKey)
          ),
    [categoriesByPermaKey]
  );

  const capitalizableCategories = useMemo(
    () =>
      !categoriesByPermaKey
        ? []
        : compact(["furniture", "computers_and_hardware"].map((x) => categoriesByPermaKey[x])),
    [categoriesByPermaKey]
  );

  const commonCategories = useMemo(
    () =>
      !categoriesByPermaKey
        ? undefined
        : {
            equity: categoriesByPermaKey["equity"],
            income: categoriesByPermaKey["income"] ?? categoriesByPermaKey["miscellaneous_income"],
            expense:
              categoriesByPermaKey["other_expenses"] ??
              categoriesByPermaKey["miscellaneous_expenses"],
            debt:
              categoriesByPermaKey["debt_convertible_note"] ??
              categoriesByPermaKey["csk_founder_loan_payable"],
            preferredEquity: categoriesByPermaKey["equity"],
            revenue: categoriesByPermaKey["transaction_income"],
            bankTransfer: categoriesByPermaKey["transfer_account"],
            transfer: categoriesByPermaKey["transfer_account"],
            transferAccount: categoriesByPermaKey["transfer_account"],
            rent: categoriesByPermaKey["rent"],
            salariesAndBenefits: categoriesByPermaKey["salaries_and_benefits"],
            noCategory: categoriesByPermaKey["no_category"],
            uncategorizedRevenue:
              categoriesByPermaKey["income"] ?? categoriesByPermaKey["miscellaneous_income"],
            uncategorizedExpenses:
              categoriesByPermaKey["uncategorized_expenses"] ??
              categoriesByPermaKey["miscellaneous_expenses"],

            // These are currently known to be optional.
            // lodash's dictionary and typescript aren't configured to treat keys as optional by default
            // (or something like that)
            ventureSafeNote: categoriesByPermaKey["venture_safe_note"],
          },
    [categoriesByPermaKey]
  );

  useEffect(() => {
    if (!isNil(commonCategories) && Object.values(commonCategories).some(isNil)) {
      console.warn(
        "Could not retrieve one of the common categories. Here are the missing permaKeys:",
        Object.entries(commonCategories)
          .filter(([, value]) => !value)
          .map(([key]) => key)
      );
    }
  }, [commonCategories]);

  const getDefaultTopCategoriesForTransaction = useCallback(
    (t: { amount: number | Big | string }) => {
      const expenseCategories = commonCategories
        ? [
            { category: commonCategories.bankTransfer, title: "Bank Transfer" },
            {
              category: commonCategories.expense,
              title: "Expense",
            },
            {
              category: commonCategories.rent,
              title: "Rent",
            },
            { category: commonCategories.salariesAndBenefits, title: "Salaries & Benefits" },
          ]
        : [];

      const revenueCategories = commonCategories
        ? [
            // OPTIONAL
            { category: commonCategories.ventureSafeNote, title: "SAFE / Angel Round" },
            {
              category: commonCategories.preferredEquity,
              title: "Preferred Equity (Priced round)",
            },
            // { category: commonCategories.debt, title: "Debt / Convertible Note" },
            { category: commonCategories.bankTransfer, title: "Bank Transfer" },
            { category: commonCategories.revenue, title: "Revenue" },
          ].filter((x) => Boolean(x.category))
        : [];
      return Big(t.amount).lte(0) ? expenseCategories : revenueCategories;
    },
    [commonCategories]
  );

  return {
    categories,
    categoriesByPermaKey,
    categoriesById,
    uncategorizedPermaKeys,
    commonCategories,
    capitalizableCategories,
    loading,
    coaReady,

    // todo share more code between the categorize transaction dialog on the API and
    // in the dashboard. This doesn't really make sense to put here, but it needs to be
    // shared between the dialog on the transactions page and the one on the api screen
    getDefaultTopCategoriesForTransaction,
  };
}
